<template>
    <div>
        <p>
            {{ $t('archivedDocumnets.tittle') }}
        </p>

        <div v-loading="loading" v-if="noDocuments">
            <el-empty :description="$t('archivedDocumnets.noDocuments')" />
        </div>

        <div v-else>

            <el-table v-loading="loading" :data="documents">

                <el-table-column prop="id" 
                                 label="Id" 
                                 width="50" />

                <el-table-column width="100"
                                 prop="documentType"
                                 :label="$t('userDocuments.documentType')">
                    <template #default="scope">
                        <FileType :document="scope.row" />
                    </template>
                </el-table-column>

                <el-table-column prop="name"
                                 :label="$t('userDocuments.name')" />

                <el-table-column :label="$t('userDocuments.language')" >
                    <template #default="scope">
                        {{ languages(scope.row) }}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('userDocuments.createdOn')" >
                    <template #default="scope">
                        {{ mapDate(scope.row.createdAt) }}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('userDocuments.updatedOn')" >
                    <template #default="scope">
                        {{ mapDateTime(scope.row.updatedAt) }}
                    </template>
                </el-table-column>

                <el-table-column :label="$t('userDocuments.progress')" >
                    <template #default="scope">
                        <ProgressTags :rowData="scope.row" />
                    </template>
                </el-table-column>

                <el-table-column fixed="right"
                                 :label="$t('userDocuments.operations')" >
                    <template #default="scope">

                        <div class="file-actions">
                            <el-button link
                                       type="primary"
                                       size="small"
                                       @click.prevent="openDoc(scope.$index)"
                                       v-on:click.middle="openDocInNewTab(scope.$index)">
                                {{ $t('archivedDocumnets.view')}}
                            </el-button>

                            <el-popover placement="right"
                                        :width="150"
                                        trigger="click">
                                <template #reference>
                                    <div class="img-button-container">
                                        <span class="material-symbols-outlined">
                                            more_horiz
                                        </span>
                                    </div>
                                </template>

                                <div class="action-wrapper">

                                    <el-button @click="openDeleteDialog(scope.$index)"
                                               type="danger"
                                               size="small">
                                        {{ $t('fileActions.delete') }}
                                    </el-button>

                                </div>

                            </el-popover>
                        </div>

                    </template>
                </el-table-column>

            </el-table>

            <el-pagination layout="prev, pager, next"
                           :total="rowCount"
                           :default-page-size="pageSize"
                           @current-change="goToPage" />

        </div>

        <el-dialog v-model="deleteDialogVisible"
                   :title="$t('fileActions.delete')"
                   width="30%"
                   align-center>
            <span>
                {{ $t('userDocuments.deleteMessage') }}
            </span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeDeleteDialog">
                        {{ $t('generic.cancel') }}
                    </el-button>
                    <el-button type="primary"
                               @click="deleteConfirm">
                        {{ $t('generic.confirm') }}
                    </el-button>
                </span>
            </template>
        </el-dialog>


    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { ConfigurationOptions, IdNamePair } from '@/models/ConfigurationOptions'
    import { RequestOptions } from '@/models/RequestOptions'

    import { navigationService } from '@/services/navigationService'

    import { homePageService } from '@/pages/Home/homePageService'
    import { translationConfigService } from '@/components/TranslationConfig/translationConfigService'

    import FileType from '@/components/Home/FileType.vue'
    import ProgressTags from '@/components/Home/TranslationProgressTags.vue'

    export default defineComponent({
        components: {
            FileType,
            ProgressTags
        },
        data() {
            return {
                
                
                loading: false,
                documents: [] as any[],
                homePageService: new homePageService(),
                translationConfigService: translationConfigService.getInstance(),

                configOptions: null as ConfigurationOptions | null,
                
                deleteDialogVisible: false,
                deleteItemId: 0,

                currentPageNumber: 1,
                pageSize: 10,
                rowCount: 0,
            };
        },
        created() {
            this.init();
        },
        computed: {
            noDocuments(): boolean {
                return this.rowCount === 0;
            },
            requestOptions(): RequestOptions {
                var request: RequestOptions = {
                    Paging: {
                        PageNumber: this.currentPageNumber,
                        PageSize: this.pageSize
                    },
                }
                return request;
            },

        },
        methods: {
            async init() {
                this.loading = true;

                await this.loadDocuments();
                this.configOptions = await this.translationConfigService.getConfigOptions();

                this.loading = false;
            },
            async loadDocuments() {
                var pagedResult = await this.homePageService.loadArchivedDocuments(this.requestOptions);
                if (pagedResult != undefined && pagedResult != null) {
                    this.rowCount = pagedResult.rowCount;
                    this.documents = pagedResult.results;
                }
            },
            async goToPage(pageNumebr: number) {
                this.currentPageNumber = pageNumebr;
                await this.loadDocuments();
            },
            languages(item: any) {
                let sourceLang = this.configOptions?.languages.find((l: IdNamePair) => l.id == item.translationConfig.sourceLanguageId);
                let targeLang = this.configOptions?.languages.find((l: IdNamePair) => l.id == item.translationConfig.targetLanguageId);

                return `${sourceLang?.name} - ${targeLang?.name}`
            },
            mapDate(dateString: any) {
                return new Date(dateString).toLocaleDateString()
            },
            mapDateTime(dateString: any) {
                return new Date(dateString).toLocaleString()
            },
            
            
            openDoc(index: number) {
                var doc = this.documents[index];
                navigationService.goArchivedDocumentPage(doc.id);
            },

            openDocInNewTab(index: number) {
                var doc = this.documents[index];
                const url = navigationService.getArchivedDocumentUrl(doc.id);
                window.open(url, '_blank', 'noreferrer');
            },
                        
            openDeleteDialog(index: number) {
                var doc = this.documents[index];
                this.deleteDialogVisible = true;
                this.deleteItemId = doc.id;
            },            
         
            async deleteConfirm() {
                this.loading = true;

                await this.homePageService.deleteArchiveDocument(this.deleteItemId);
                await this.loadDocuments();

                this.loading = false;

                this.closeDeleteDialog()
            },
                        
            closeDeleteDialog() {
                this.deleteDialogVisible = false;
                this.deleteItemId = 0;
            },
       }
    })
</script>

<style lang="scss" scoped>
    .action-wrapper {
        display: flex;
        flex-direction: column;

        & > button {
            margin: 5px 0;
        }
    }

    .file-actions {
        display: flex;
        justify-content: space-around;
    }
</style>