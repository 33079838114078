import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slot-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SourceSegment = _resolveComponent("SourceSegment")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'sample-segment': _ctx.sampleSegment}, 'merge-result'])
  }, [
    _createVNode(_component_el_row, { gutter: 24 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          offset: 1,
          span: 10
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SourceSegment, {
              modelValue: _ctx.textData,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textData) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 10 }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "empty-target" }, null, -1)
          ])),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 1 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ], 2))
}