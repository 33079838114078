
import { notificationService } from '@/services/notificationService'

import { segmentModificationService } from '@/services/translationSegment/segmentModificationService';
import { segmentApiService } from '@/services/translationSegment/segmentApiService';

import { ServiceStatusType } from '@/models/ServiceStatusType';
import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
import { TranslationConfiguration } from '@/models/TranslationConfiguration';
import { ExternalTranslationResponse, ExternalTranslationStatus } from '@/models/ExternalTranslationResponse'

import { externalProvidersStore } from '@/stores/externalProvidersStore';

import { serviceStatusManager } from './serviceStatusManager';

export class externalTranslationService {
    private static instance: externalTranslationService;

    notificationService: notificationService;
    segmentModificationService: segmentModificationService;
    segmentApiService: segmentApiService;
    statusManager: serviceStatusManager;

    externalProvidersStore: any;

    t: any;

    private constructor(t: any) {
        this.statusManager = new serviceStatusManager();
        this.notificationService = new notificationService();

        this.segmentModificationService = segmentModificationService.getInstance();
        this.segmentApiService = segmentApiService.getInstance();

        this.externalProvidersStore = externalProvidersStore();

        this.t = t;
    }

    public static getInstance(t: any): externalTranslationService {
        if (!externalTranslationService.instance) {
            externalTranslationService.instance = new externalTranslationService(t);
        }
        return externalTranslationService.instance;
    }

    async translateExternallyAction(segment: TranslationSegment, configuration: TranslationConfiguration): Promise<boolean> {
        const externalTranslationResponse = await this.performExternalTranslation(segment, configuration);

        if (!externalTranslationResponse) {
            return false;
        }

        return this.handleTranslationResponse(segment, externalTranslationResponse, configuration);
    }

    private async performExternalTranslation(segment: TranslationSegment, configuration: TranslationConfiguration): Promise<ExternalTranslationResponse | null> {
        try {
            const response = await this.segmentApiService.translateExternallySegment(segment, configuration);
            return response as ExternalTranslationResponse;
        } catch (error) {
            this.notificationService.warning(this.t('externalTranslations.apiError'), this.t('externalTranslations.tryAgainLater'));
            return null;
        }
    }

    private async handleTranslationResponse(segment: TranslationSegment, externalTranslationResponse: ExternalTranslationResponse, configuration: TranslationConfiguration): Promise<boolean> {
        const translationProvider = configuration.externalTranslationProviders[0];

        switch (externalTranslationResponse.externalTranslationStatus) {
            case ExternalTranslationStatus.Translated:
                return await this.handleTranslated(externalTranslationResponse, segment);

            case ExternalTranslationStatus.UserLimitReached:
                return this.handleUserLimitReached();

            case ExternalTranslationStatus.ServiceLimit:
                this.handleServiceLimit(translationProvider);
                return false;

            case ExternalTranslationStatus.TooManyRequest:
                this.handleTooManyRequests(translationProvider);
                return false;

            case ExternalTranslationStatus.ConfigurationError:
                this.notificationService.warning(this.t('externalTranslations.laguagesAreNotSupported'), this.t('externalTranslations.providerDoNotSupportLanguages'));
                return false;

            case ExternalTranslationStatus.TranslationError:
                this.notificationService.warning(this.t('externalTranslations.apiError'), this.t('externalTranslations.tryAgainLater'));
                return false;

            default:
                return false;
        }
    }

    private async handleTranslated(externalTranslationResponse: ExternalTranslationResponse, segment: TranslationSegment): Promise<boolean> {
        this.segmentModificationService.mapTranslationSegment(segment, externalTranslationResponse.translationSegment);
        await this.statusManager.updateQuotaPercent(externalTranslationResponse.groupCharactersCount);
        return true;
    }

    private handleUserLimitReached(): boolean {
        this.statusManager.disableService();
        this.notifyLimitReached();
        return false;
    }

    private handleServiceLimit(translationProvider: any): void {
        this.notificationService.warning(this.t('externalTranslations.translationError'), this.t('externalTranslations.serviceNotAvailable'));
        this.externalProvidersStore.disableProviderForDay(translationProvider);
    }

    private handleTooManyRequests(translationProvider: any): void {
        this.notificationService.warning(this.t('externalTranslations.translationError'), this.t('externalTranslations.tooManyRequests'));
        this.externalProvidersStore.disableProviderForThreeMinutes(translationProvider);
    }

    private notifyLimitReached(): void {
        const title = this.t('externalTranslations.limitReached');
        const messageHtml = this.getNotificationHtml();
        this.notificationService.warningPermanentHtml(title, messageHtml);
    }

    //Comments until we have new production link

    //getNotificationHtml() {
    //    const message = this.t('externalTranslations.limitMessage');
    //    const linkText = this.t('externalTranslations.upgradeLicenseToContinue');

    //    const link = process.env.VUE_APP_SOULUTIONS_AND_PRICES;

    //    const messageHtml = `<p>${message}</p> </br> ` +
    //        `<a href="${link}" target="_blank" rel="noopener noreferrer" style="text-decoration: none;">${linkText}</a>`

    //    return messageHtml;
    //}

    getNotificationHtml() {
        const message = this.t('externalTranslations.limitMessage');
        const contact = this.t('externalTranslations.contactWWS');

        const messageHtml = `<p>${message}</p> </br> ` +
            `<b>${contact}</b></a>`

        return messageHtml;
    }

    reachedSeventyFivePercent() {
        return this.statusManager.reachedPercentageLimit(75);
    }

    reachedNinetyPercent() {
        return this.statusManager.reachedPercentageLimit(90);
    }

    reachedHundredPercent() {
        return this.statusManager.reachedPercentageLimit(100);
    }

    isServiceStatusOk() {
        const status = this.statusManager.geterviceStatus();
        return status == ServiceStatusType.Ok;
    }
}

