<template>
    <div v-if="showLicenseMessage"
         class="limit-container">
        <el-tag size="large"
                :type="tagType"
                effect="light">
            {{licenseWarningMessage}}
        </el-tag>
        <!--commentted out because of the missing link-->
        <!--<el-button type="primary"
                    tag="a"
                    :href="solutionsAndPricesUrl"
                    target="_blank"
                    rel="noopener noreferrer">
            {{upgradeLicenceMessage}}
        </el-button>-->
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import { externalTranslationService } from './externalTranslationService';


    export default defineComponent({
        data() {
            return {
                externalTranslationService: externalTranslationService.getInstance(this.$t),
                showLicenseMessage: false,
                licenseWarningMessage: '',
                tagType: 'warning',

                seventyFivePercentMessage: this.$t('externalTranslations.seventyFivePercentWarning'),
                ninetyPercentMessage: this.$t('externalTranslations.ninetyPercentWarning'),
                limitReachedMessage: this.$t('externalTranslations.limitReachedMessage'),

                upgradeLicenceMessage: this.$t('externalTranslations.upgradeYourLicense'),
            }
        },
        created() {
            this.updateMessage();
        },
        computed: {
            solutionsAndPricesUrl(): string {
                return process.env.VUE_APP_SOULUTIONS_AND_PRICES;
            },
        },
        methods: {
            updateMessage() {
                if (this.externalTranslationService.reachedHundredPercent()) {
                    this.showLicenseMessage = true;
                    this.licenseWarningMessage = this.limitReachedMessage;
                    this.tagType = 'danger';
                } else if (this.externalTranslationService.reachedNinetyPercent()) {
                    this.showLicenseMessage = true;
                    this.licenseWarningMessage = this.ninetyPercentMessage;
                    this.tagType = 'warning';
                } else if (this.externalTranslationService.reachedSeventyFivePercent()) {
                    this.showLicenseMessage = true;
                    this.licenseWarningMessage = this.seventyFivePercentMessage;
                    this.tagType = 'warning';
                }
            },
        }

    })

</script>

<style scoped lang="scss">
    .limit-container {
        display: flex;
        justify-content: right;

        & > * {
            margin-right: 10px;
        }
    }
</style>