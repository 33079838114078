import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_col, {
      span: 6,
      id: "source-lagnuage"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('translationConfig.sourceLanguage')), 1),
        _createVNode(_component_el_select, {
          modelValue: _ctx.configuration.sourceLanguageId,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.configuration.sourceLanguageId) = $event)),
          onChange: _ctx.valueChanged,
          "default-first-option": "",
          filterable: "",
          placeholder: _ctx.$t('translationConfig.sourceLanguage'),
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sourceLanguages, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: `${item.code} (${item.name})`,
                value: item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange", "placeholder", "disabled"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_col, {
      class: "centred-container",
      id: "swap-languages",
      span: 1
    }, {
      default: _withCtx(() => [
        (!_ctx.disabled)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "swap-img-container",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.swapLanguages && _ctx.swapLanguages(...args)))
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("span", { class: "material-symbols-outlined" }, " sync_alt ", -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_el_col, {
      span: 5,
      id: "target-laguage"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('translationConfig.targetLanguage')), 1),
        _createVNode(_component_el_select, {
          modelValue: _ctx.configuration.targetLanguageId,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.configuration.targetLanguageId) = $event)),
          onChange: _ctx.valueChanged,
          "default-first-option": "",
          filterable: "",
          placeholder: _ctx.$t('translationConfig.targetLanguage'),
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.targetLanguages, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: `${item.code} (${item.name})`,
                value: item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange", "placeholder", "disabled"])
      ]),
      _: 1
    })
  ], 64))
}