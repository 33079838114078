import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "config-value-combined" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguagesSetup = _resolveComponent("LanguagesSetup")!
  const _component_FuzzyMatch = _resolveComponent("FuzzyMatch")!
  const _component_VariantsPopup = _resolveComponent("VariantsPopup")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_SubjectAndSubsubject = _resolveComponent("SubjectAndSubsubject")!
  const _component_ProjectCode = _resolveComponent("ProjectCode")!
  const _component_DatabaseType = _resolveComponent("DatabaseType")!
  const _component_ExcelConfiguration = _resolveComponent("ExcelConfiguration")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_ExternalProviders = _resolveComponent("ExternalProviders")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_card, null, {
      default: _withCtx(() => [
        (_ctx.configuration)
          ? (_openBlock(), _createBlock(_component_el_collapse, {
              key: 0,
              modelValue: _ctx.collapseModel,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.collapseModel) = $event)),
              onChange: _ctx.handleChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_collapse_item, {
                  id: "configuration-colapse",
                  name: "1"
                }, {
                  title: _withCtx(() => [
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "img-container" }, [
                      _createElementVNode("span", { class: "material-symbols-outlined" }, "settings")
                    ], -1)),
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.configTittle), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, { gutter: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_LanguagesSetup, {
                          modelValue: _ctx.configuration,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.configuration) = $event)),
                          disabled: _ctx.viewMode,
                          onValueChanged: _ctx.debouncedHandler
                        }, null, 8, ["modelValue", "disabled", "onValueChanged"]),
                        _createVNode(_component_FuzzyMatch, {
                          modelValue: _ctx.configuration,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.configuration) = $event)),
                          disabled: _ctx.viewMode,
                          onValueChanged: _ctx.debouncedHandler
                        }, null, 8, ["modelValue", "disabled", "onValueChanged"]),
                        _createVNode(_component_VariantsPopup, {
                          modelValue: _ctx.configuration,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.configuration) = $event)),
                          disabled: _ctx.viewMode,
                          onValueChanged: _ctx.debouncedHandler
                        }, null, 8, ["modelValue", "disabled", "onValueChanged"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_row, { gutter: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SubjectAndSubsubject, {
                          modelValue: _ctx.configuration,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.configuration) = $event)),
                          disabled: _ctx.viewMode,
                          onValueChanged: _ctx.debouncedHandler
                        }, null, 8, ["modelValue", "disabled", "onValueChanged"]),
                        _createVNode(_component_ProjectCode, {
                          modelValue: _ctx.configuration,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.configuration) = $event)),
                          disabled: _ctx.viewMode,
                          onValueChanged: _ctx.debouncedHandler
                        }, null, 8, ["modelValue", "disabled", "onValueChanged"]),
                        _createVNode(_component_DatabaseType, {
                          modelValue: _ctx.configuration,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.configuration) = $event)),
                          disabled: _ctx.viewMode,
                          onValueChanged: _ctx.debouncedHandler
                        }, null, 8, ["modelValue", "disabled", "onValueChanged"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ExcelConfiguration, {
                      modelValue: _ctx.configuration,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.configuration) = $event)),
                      disabled: !_ctx.excelConfigEditable || _ctx.viewMode,
                      documentType: _ctx.documentType,
                      onConfigChanged: _ctx.debouncedHandler
                    }, null, 8, ["modelValue", "disabled", "documentType", "onConfigChanged"]),
                    _createVNode(_component_el_divider),
                    _createVNode(_component_el_row, { gutter: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ExternalProviders, {
                          modelValue: _ctx.configuration,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.configuration) = $event)),
                          disabled: _ctx.viewMode,
                          onValueChanged: _ctx.debouncedHandler
                        }, null, 8, ["modelValue", "disabled", "onValueChanged"])
                      ]),
                      _: 1
                    }),
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                  ]),
                  _: 3
                })
              ]),
              _: 3
            }, 8, ["modelValue", "onChange"]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}