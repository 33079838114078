import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "no-variants" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "variants-grid table-header" }
const _hoisted_5 = { class: "grid-span-2" }
const _hoisted_6 = { class: "translation-variants-container" }
const _hoisted_7 = ["id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SourceItem = _resolveComponent("SourceItem")!
  const _component_GroupInfo = _resolveComponent("GroupInfo")!
  const _component_TargetText = _resolveComponent("TargetText")!
  const _component_MarchPersentage = _resolveComponent("MarchPersentage")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.noVariants)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('translationVariants.noVariantsInGroup')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.translationDate), 1)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('translationVariants.source')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('translationVariants.translation')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('translationVariants.match')), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_scrollbar, { "max-height": "290" }, {
              default: _withCtx(() => [
                _createVNode(_TransitionGroup, {
                  tag: "div",
                  name: "list",
                  class: "container",
                  appear: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.translationVariants, (variant, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: variant.targetSegment + index,
                        class: "translation-variant-wrapper"
                      }, [
                        _createElementVNode("div", {
                          id: `v-${index}`,
                          class: _normalizeClass(['translation-variant', 'item', 'variants-grid', {'selected': _ctx.translationVariantSelected(variant)}]),
                          onClick: ($event: any) => (_ctx.setTranslationVariant(variant))
                        }, [
                          _createVNode(_component_SourceItem, {
                            sourceData: _ctx.translationSegment.sourceData,
                            translationVariant: variant
                          }, null, 8, ["sourceData", "translationVariant"]),
                          _createVNode(_component_GroupInfo, { translationVariant: variant }, null, 8, ["translationVariant"]),
                          _createVNode(_component_TargetText, { translationVariant: variant }, null, 8, ["translationVariant"]),
                          _createVNode(_component_MarchPersentage, { translationVariant: variant }, null, 8, ["translationVariant"])
                        ], 10, _hoisted_7)
                      ]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]))
  ]))
}