<template>
    <el-col :span="6"
            id="database-type">

        <p>
            {{ $t('translationConfig.database') }}
        </p>
        <el-select v-model="configuration.databaseType"
                   @change="valueChanged"
                   placeholder="Database"
                   :disabled="disabled">

            <el-option v-for="(item) in databases"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id" />

        </el-select>
    </el-col>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { TranslationConfiguration } from '@/models/TranslationConfiguration'
    import { ConfigurationOptions } from '@/models/ConfigurationOptions'

    import { translationConfigService } from '../translationConfigService'

    export default defineComponent({
        props: {
            modelValue: Object,
            disabled: {
                type: Boolean,
                defaultValue: false
            },
        },
        emits: ['input', 'valueChanged'],
        data() {
            return {
                translationConfigService: translationConfigService.getInstance(),
                configurationOptions: null as ConfigurationOptions | null,
            }
        },
        mounted() {
            this.init();
        },
        computed: {
            configuration: {
                get(): TranslationConfiguration { return this.modelValue as TranslationConfiguration; },
                set(value: TranslationConfiguration) { this.$emit('input', value); }
            },
            databases(): any {
                return this.configurationOptions?.databaseTypes;
            },
        },
        methods: {
            async init() {
                this.configurationOptions = await this.translationConfigService.getConfigOptions()
            },
            valueChanged() {
                this.$emit('valueChanged');
            },
        }

    })

</script>
