import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "segment-tail" }
const _hoisted_2 = { class: "option-buttons" }
const _hoisted_3 = {
  id: "info-items",
  class: "info-items"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonTemplate = _resolveComponent("ButtonTemplate")!
  const _component_MatchPersentage = _resolveComponent("MatchPersentage")!
  const _component_StateTag = _resolveComponent("StateTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ButtonTemplate, {
        id: "save-button",
        class: "option-item",
        disabled: !_ctx.showSave,
        tooltipMessage: _ctx.saveTranslationTooltip,
        onButtonClick: _ctx.doAddTranslation
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("span", { class: "material-symbols-outlined" }, " add_comment ", -1)
        ])),
        _: 1
      }, 8, ["disabled", "tooltipMessage", "onButtonClick"]),
      _createVNode(_component_ButtonTemplate, {
        id: "update-button",
        class: "option-item",
        disabled: !_ctx.showUpdate,
        tooltipMessage: _ctx.updateTranslationTooltip,
        onButtonClick: _ctx.doUpdateTranslation
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("span", { class: "material-symbols-outlined" }, " system_update_alt ", -1)
        ])),
        _: 1
      }, 8, ["disabled", "tooltipMessage", "onButtonClick"]),
      _createVNode(_component_ButtonTemplate, {
        id: "clear-button",
        class: "option-item",
        disabled: !_ctx.showClear,
        tooltipMessage: _ctx.eraceTranslationTooltip,
        onButtonClick: _ctx.doClearTranslation
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createElementVNode("span", { class: "material-symbols-outlined" }, " ink_eraser ", -1)
        ])),
        _: 1
      }, 8, ["disabled", "tooltipMessage", "onButtonClick"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MatchPersentage, {
          modelValue: _ctx.translationSegment,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.translationSegment) = $event))
        }, null, 8, ["modelValue"]),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentType(_ctx.translationSegment)), {
          modelValue: _ctx.translationSegment,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.translationSegment) = $event))
        }, null, 8, ["modelValue"]))
      ])
    ]),
    _createVNode(_component_StateTag, {
      modelValue: _ctx.translationSegment,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.translationSegment) = $event))
    }, null, 8, ["modelValue"])
  ]))
}