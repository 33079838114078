<template>
    <div class="find-next-segmet-container">

        <el-tooltip v-if="showMoveToNextWorkSegment"
                    placement="top-start"
                    :content="nextTranslateSegmentTooltip"
                    :show-after="5">

            <div id="find-next-segmet-container"
                 class="img-circle">
                <div @click="nextTranslateSegment"
                     class="img-button-container">
                    <span class="material-symbols-outlined">
                        move_down
                    </span>
                </div>
            </div>


        </el-tooltip>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { shortcuts } from '@/utils/shortcuts';
    import { documentStore } from '@/stores/documentStore'

    export default defineComponent({
        props: {
            translationLoadded: {
                type: Boolean,
                default: false
            },
            focused: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                documentStore: documentStore(),
                nextTranslateSegmentTooltip: shortcuts.getTooltipTranslated(this.$t, shortcuts.nextTranslateSegment),
            }
        },
        computed: {
            showMoveToNextWorkSegment(): boolean {
                return this.translationLoadded &&
                    this.focused &&
                    !this.documentStore.loading;
            },

        },
        methods: {
            nextTranslateSegment() {
                this.$emit('nextTranslateSegment');
            }
        }

    });
</script>

<style lang="scss" scoped>

    .find-next-segmet-container {
        right: -78px;
        position: absolute;
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    .img-circle {
        background-color: white;
        border-radius: 24px;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
    }

    .focused-row .find-next-segmet-container {
        opacity: 0.6;
    }
</style>
