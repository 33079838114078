import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_col, {
      span: 6,
      id: "use-external-translations"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('translationConfig.useExternalTranslation')), 1),
        _createVNode(_component_el_checkbox, {
          modelValue: _ctx.configuration.useExternalTranslation,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.configuration.useExternalTranslation) = $event)),
          onChange: _ctx.valueChanged,
          size: "large",
          disabled: _ctx.disabled
        }, null, 8, ["modelValue", "onChange", "disabled"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_col, { span: 1 }),
    _createVNode(_component_el_col, {
      span: 5,
      id: "translation-providers"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('translationConfig.translationProvider')), 1),
        _createVNode(_component_el_select, {
          modelValue: _ctx.configuration.externalTranslationProviders,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.configuration.externalTranslationProviders) = $event)),
          "multiple-limit": 1,
          multiple: "",
          onChange: _ctx.valueChanged,
          placeholder: "Provider not selected",
          disabled: _ctx.externalProvidersDisabled
        }, {
          empty: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('translationConfig.noProviderSupportThisLanguages')), 1)
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.externalProvidersOptions, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: `${item.name}`,
                value: item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange", "disabled"])
      ]),
      _: 1
    })
  ], 64))
}