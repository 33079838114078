import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "name" }
const _hoisted_3 = { class: "sub-components" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserComponent = _resolveComponent("UserComponent")!
  const _component_ShortcutsInfo = _resolveComponent("ShortcutsInfo")!
  const _component_LanguageSwitch = _resolveComponent("LanguageSwitch")!
  const _component_NewUserPrompt = _resolveComponent("NewUserPrompt")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        id: "go-home",
        class: "sub-components clickable",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToHomePage && _ctx.goToHomePage(...args)))
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "logo" }, null, -1)),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.appName), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createVNode(_component_UserComponent)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_ShortcutsInfo)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_LanguageSwitch)
        ])
      ])
    ]),
    _createVNode(_component_NewUserPrompt)
  ], 64))
}