import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      id: "clear-document",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearSegmentsDialogVisible = true)),
      plain: "",
      type: "danger"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('clearDocument.buttonClearDocument')) + " ", 1),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "bttn-img" }, [
          _createElementVNode("span", { class: "material-symbols-outlined" }, " ink_eraser ")
        ], -1))
      ]),
      _: 1
    }),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.clearSegmentsDialogVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.clearSegmentsDialogVisible) = $event)),
      title: _ctx.$t('clearDocument.titleClearDocument'),
      width: "30%",
      "align-center": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, [
          _createVNode(_component_el_button, { onClick: _ctx.closeClearSegmentsDialog }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('generic.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.clearAllSegments
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('generic.confirm')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('clearDocument.popupMessage')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}