import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "info" }
const _hoisted_2 = {
  key: 0,
  class: "material-symbols-outlined"
}
const _hoisted_3 = {
  key: 1,
  class: "material-symbols-outlined"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.translationIsOwn)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " person "))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, " group "))
  ]))
}