import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "split-source" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { id: "split-result" }
const _hoisted_4 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleSegment = _resolveComponent("SimpleSegment")!
  const _component_ControlWrapper = _resolveComponent("ControlWrapper")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_ControlWrapper, {
    "wrapper-title": _ctx.$t('splitSegment.splitSegment'),
    onConfirm: _ctx.saveAction,
    onCancel: _ctx.closeAction
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('splitSegment.sourceSegment')), 1),
        _createVNode(_component_SimpleSegment, {
          modelValue: _ctx.segmentItem.data.sourceData,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.segmentItem.data.sourceData) = $event)),
          "sample-segment": true
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('splitSegment.resultSegments')), 1),
        _withDirectives((_openBlock(), _createElementBlock("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mergedSegments, (segment) => {
            return (_openBlock(), _createElementBlock("div", {
              key: segment.translationSegmentId
            }, [
              _createVNode(_component_SimpleSegment, {
                modelValue: segment.sourceData,
                "onUpdate:modelValue": ($event: any) => ((segment.sourceData) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]))
          }), 128))
        ])), [
          [_directive_loading, _ctx.segmentsLoading]
        ])
      ])
    ]),
    _: 1
  }, 8, ["wrapper-title", "onConfirm", "onCancel"])), [
    [_directive_loading, _ctx.loading]
  ])
}