import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "green"
}
const _hoisted_4 = {
  key: 1,
  class: "blue"
}
const _hoisted_5 = {
  key: 2,
  class: "orange"
}
const _hoisted_6 = {
  key: 3,
  class: "grey"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "grey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.documentIsActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.fileReady)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('fileState.done')), 1))
            : _createCommentVNode("", true),
          (_ctx.newFile)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('fileState.new')), 1))
            : _createCommentVNode("", true),
          (_ctx.fileInProgress)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('fileState.inProgress')), 1))
            : _createCommentVNode("", true),
          (_ctx.dumentIsEmpty)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('fileState.isEmpty')), 1))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('fileState.readingIssue')), 1)
        ]))
  ]))
}