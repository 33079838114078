import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "translate-copy" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonTemplate = _resolveComponent("ButtonTemplate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ButtonTemplate, {
      id: "copy-button",
      disabled: _ctx.copyDisabled,
      tooltipMessage: _ctx.copyTooltip,
      onButtonClick: _ctx.doCopy
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("span", { class: "material-symbols-outlined" }, " content_copy ", -1)
      ])),
      _: 1
    }, 8, ["disabled", "tooltipMessage", "onButtonClick"]),
    _createVNode(_component_ButtonTemplate, {
      id: "translate-button",
      disabled: false,
      tooltipMessage: _ctx.translateTooltip,
      onButtonClick: _ctx.doTranslate
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("span", { class: "material-symbols-outlined" }, " translate ", -1)
      ])),
      _: 1
    }, 8, ["tooltipMessage", "onButtonClick"])
  ]))
}