import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "go-back-container" }
const _hoisted_2 = { class: "img-circle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tooltip, {
      placement: "top-start",
      content: _ctx.$t('helpButtons.goBack'),
      "show-after": 5
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBackAction && _ctx.goBackAction(...args))),
            class: "img-button-container"
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("span", { class: "material-symbols-outlined" }, " arrow_back ", -1)
          ]))
        ])
      ]),
      _: 1
    }, 8, ["content"])
  ]))
}