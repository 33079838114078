<template>

    <el-button id="clear-document"
               @click="clearSegmentsDialogVisible = true"
               plain
               type="danger">

        {{$t('clearDocument.buttonClearDocument')}}

        <div class="bttn-img">
            <span class="material-symbols-outlined">
                ink_eraser
            </span>
        </div>
    </el-button>

    <el-dialog v-model="clearSegmentsDialogVisible"
               :title="$t('clearDocument.titleClearDocument')"
               width="30%"
               align-center>
        <div class="message">
            {{$t('clearDocument.popupMessage')}}
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closeClearSegmentsDialog">
                    {{$t('generic.cancel')}}
                </el-button>
                <el-button type="primary" @click="clearAllSegments">
                    {{$t('generic.confirm')}}
                </el-button>
            </span>
        </template>
    </el-dialog>

</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        emits: ['clearConfirmed'],
        data() {
            return {
                clearSegmentsDialogVisible: false,
            }
        },
        methods: {
            clearAllSegments() {
                this.$emit('clearConfirmed');
                this.closeClearSegmentsDialog();
            },
            closeClearSegmentsDialog() {
                this.clearSegmentsDialogVisible = false;
            },
        }
    })

</script>

<style lang="scss" scoped>
    .bttn-img {
        margin-left: 10px;
    }

    .message {
        font-size: 1.1em;
    }
</style>
