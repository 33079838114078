import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "merge-control",
  class: "merge-control"
}
const _hoisted_2 = { class: "merge-header" }
const _hoisted_3 = { class: "control-name" }
const _hoisted_4 = { class: "footer-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.wrapperTitle), 1),
      _createElementVNode("div", {
        class: "close-icon",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeMergeAction && _ctx.closeMergeAction(...args)))
      }, [
        _createVNode(_component_el_tooltip, {
          placement: "top",
          content: _ctx.discardTooltip,
          "show-after": 10
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("span", { class: "material-symbols-outlined" }, " close ", -1)
          ])),
          _: 1
        }, 8, ["content"])
      ])
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_tooltip, {
        placement: "top",
        content: _ctx.saveTooltip,
        "show-after": 10
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            id: "save-merge",
            type: "success",
            onClick: _ctx.saveMergeAction
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('generic.save')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["content"]),
      _createVNode(_component_el_tooltip, {
        placement: "top",
        content: _ctx.discardTooltip,
        "show-after": 10
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            id: "cancel-merge",
            type: "danger",
            onClick: _ctx.closeMergeAction
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('generic.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["content"])
    ])
  ]))
}