import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "translations" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "statistic-container" }
const _hoisted_4 = { class: "statistic-card" }
const _hoisted_5 = { style: {"display":"inline-flex","align-items":"center"} }
const _hoisted_6 = { class: "statistic-card" }
const _hoisted_7 = { style: {"display":"inline-flex","align-items":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoBackButton = _resolveComponent("GoBackButton")!
  const _component_el_statistic = _resolveComponent("el-statistic")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_GoBackButton)
    ]),
    _createVNode(_component_el_tabs, { type: "border-card" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: _ctx.$t('userInfo.externalTranslationUsage')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_el_row, { gutter: 16 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_el_statistic, { value: _ctx.userCharacters }, {
                          title: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('userInfo.charactersUserDotay')), 1)
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_el_statistic, { value: _ctx.groupCharacters }, {
                          title: _withCtx(() => [
                            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('userInfo.charactersUserByGroup')), 1)
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('userInfo.lastLogEntries')), 1),
            _withDirectives((_openBlock(), _createElementBlock("div", null, [
              _createVNode(_component_el_table, { data: _ctx.lastLogItems }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    label: _ctx.$t('generic.date')
                  }, {
                    default: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString(_ctx.mapDate(scope.row.date)), 1)
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    label: _ctx.$t('userInfo.translationProvider')
                  }, {
                    default: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString(_ctx.mapProviderName(scope.row.translationProvider)), 1)
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    prop: "charactersTranslatedCount",
                    label: _ctx.$t('userInfo.charactersUsed')
                  }, null, 8, ["label"])
                ]),
                _: 1
              }, 8, ["data"])
            ])), [
              [_directive_loading, _ctx.loading]
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('userInfo.logsByDay')), 1),
            _withDirectives((_openBlock(), _createElementBlock("div", null, [
              _createVNode(_component_el_table, { data: _ctx.dailyTranslationUsages }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    label: _ctx.$t('generic.date')
                  }, {
                    default: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString(_ctx.mapDate(scope.row.date)), 1)
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, {
                    prop: "charactersTranslated",
                    label: _ctx.$t('userInfo.charactersUsed')
                  }, null, 8, ["label"])
                ]),
                _: 1
              }, 8, ["data"])
            ])), [
              [_directive_loading, _ctx.loading]
            ])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    })
  ]))
}