import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "merge-source" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  id: "remove-merge-segment"
}
const _hoisted_4 = {
  key: 0,
  id: "add-merge-segment"
}
const _hoisted_5 = { id: "merge-result" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_SimpleSegment = _resolveComponent("SimpleSegment")!
  const _component_SegmentStatusIcon = _resolveComponent("SegmentStatusIcon")!
  const _component_ControlWrapper = _resolveComponent("ControlWrapper")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_ControlWrapper, {
    "wrapper-title": _ctx.$t('mergeSegment.mergeSgments'),
    onConfirm: _ctx.saveMergeAction,
    onCancel: _ctx.closeMergeAction
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('mergeSegment.sourceSegments')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.segmentsToMerge, (segment, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'm'+segment.translationSegmentId
          }, [
            _createVNode(_component_SimpleSegment, {
              modelValue: segment.data.sourceData,
              "onUpdate:modelValue": ($event: any) => ((segment.data.sourceData) = $event),
              "sample-segment": true
            }, {
              default: _withCtx(() => [
                (_ctx.showRemove(index))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_el_tooltip, {
                        placement: "top",
                        content: _ctx.removeTooltip,
                        "show-after": 10
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_button, {
                            type: "warning",
                            onClick: _ctx.removeLineAction
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('mergeSegment.remove')), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }, 8, ["content"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue"])
          ]))
        }), 128)),
        _createVNode(_component_el_tooltip, {
          placement: "top",
          content: _ctx.addTooltip,
          "show-after": 10
        }, {
          default: _withCtx(() => [
            (_ctx.showAdd())
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _ctx.addLineAction
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('mergeSegment.add')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["content"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('mergeSegment.mergeResult')), 1),
        _createVNode(_component_SimpleSegment, {
          modelValue: _ctx.mergedSourceData,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mergedSourceData) = $event))
        }, {
          default: _withCtx(() => [
            (_ctx.resultSegmentTooLong(_ctx.mergedSourceData))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_SegmentStatusIcon)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }, 8, ["wrapper-title", "onConfirm", "onCancel"])), [
    [_directive_loading, _ctx.loading]
  ])
}