import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_col = _resolveComponent("el-col")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_col, {
      span: 6,
      id: "translation-subject"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('translationConfig.subject')), 1),
        _createVNode(_component_el_select, {
          modelValue: _ctx.configuration.subjectId,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.configuration.subjectId) = $event)),
          onChange: _ctx.clearSubSubjects,
          "default-first-option": "",
          filterable: "",
          placeholder: _ctx.$t('translationConfig.subject'),
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subjects, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.name,
                value: item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange", "placeholder", "disabled"])
      ]),
      _: 1
    }),
    _createVNode(_component_el_col, { span: 1 }),
    _createVNode(_component_el_col, {
      span: 5,
      id: "translation-sub-subject"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('translationConfig.subsubject')), 1),
        _createVNode(_component_el_select, {
          modelValue: _ctx.subSubject,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.subSubject) = $event)),
          onChange: _ctx.valueChanged,
          clearable: "",
          "default-first-option": "",
          filterable: "",
          placeholder: _ctx.$t('translationConfig.notSelected'),
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subsubjects, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.name,
                value: item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange", "placeholder", "disabled"])
      ]),
      _: 1
    })
  ], 64))
}