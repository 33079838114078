import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "loader"
}
const _hoisted_3 = { class: "loading-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserHeader = _resolveComponent("UserHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BasePage = _resolveComponent("BasePage")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_BasePage, null, {
            default: _withCtx(() => [
              _createVNode(_component_UserHeader),
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('generic.loading')) + "... ", 1),
          _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
            [_directive_loading, !_ctx.loaded]
          ])
        ]))
  ]))
}