import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "external-providers" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_LicenseMessage = _resolveComponent("LicenseMessage")!
  const _component_ExternalVariantsView = _resolveComponent("ExternalVariantsView")!
  const _component_LoadExternalTranslations = _resolveComponent("LoadExternalTranslations")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_divider),
    (_openBlock(), _createBlock(_component_LicenseMessage, { key: _ctx.licenseMessageUpdate })),
    (_ctx.extarnalTranslations)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ExternalVariantsView, { translationSegment: _ctx.translationSegment }, null, 8, ["translationSegment"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.externalServiceAvailable)
            ? (_openBlock(), _createBlock(_component_LoadExternalTranslations, {
                key: 0,
                serviceAvailable: _ctx.serviceAvailable,
                onLoadButtonClick: _ctx.loadExternalTranslations
              }, null, 8, ["serviceAvailable", "onLoadButtonClick"]))
            : _createCommentVNode("", true)
        ]))
  ])), [
    [_directive_loading, _ctx.translationsLoading]
  ])
}