import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/images/Tours/text-style-tour.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tour_step = _resolveComponent("el-tour-step")!
  const _component_el_tour = _resolveComponent("el-tour")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_button, {
      type: "primary",
      onClick: _ctx.startStuleTour,
      disabled: !_ctx.tourAvailable
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.tourName), 1)
      ]),
      _: 1
    }, 8, ["onClick", "disabled"]),
    _createVNode(_component_el_tour, {
      modelValue: _ctx.tourStart,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tourStart) = $event)),
      "target-area-clickable": false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tour_step, {
          target: _ctx.targetOne,
          title: _ctx.tittleOne
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.descriptionOne), 1)
          ]),
          _: 1
        }, 8, ["target", "title"]),
        _createVNode(_component_el_tour_step, {
          target: _ctx.targetTwo,
          title: _ctx.tittleTwo,
          "content-style": _ctx.contentStyleTwo
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.descriptionTwo), 1),
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              class: "screenshot",
              src: _imports_0,
              alt: "tour.png"
            }, null, -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subDescriptionsTwo, (decs, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(decs), 1))
            }), 128))
          ]),
          _: 1
        }, 8, ["target", "title", "content-style"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}