<template>
    <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/quill@2.0.3/dist/quill.bubble.css" />
    <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'App',
  components: {
  }
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

    html {
        font: 62.5% / 1.15 sans-serif;
    }

    body {
        width: 100%;
        max-width: 140rem;
        margin: 0 auto;
        font: 1.6rem/1.25 "Helvetica Neue", Helvetica, Arial, sans-serif;
        background-color: #f5f5f5;
        color: #4d4d4d;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }

    @media screen and (min-width: 620px) {
        body {
            font-size: 1.9rem;
            line-height: 1.31579;
        }
    }

    .img-container,
    .img-button-container {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .img-small-button-container {
        width: 24px;
        height: 24px;
    }

    .img-small-button-container,
    .img-button-container {
        cursor: pointer;
        opacity: 0.6;
        transition: 0.3s;

        &:hover {
            opacity: 1;
        }
    }

    .button-disabled,
    .button-disabled:hover {
        cursor: context-menu;
        opacity: 0.3;
    }

    .hidden {
        display: none;
    }

    .show {
        display: block;
    }

</style>
