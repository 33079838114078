import { apiClient } from '@/utils/apiClient'
import { clientCache } from "@/utils/clientCache";
import { ConfigurationOptions, BasicConfigOptions, UserConfigOptions, IdNamePair } from '@/models/ConfigurationOptions';

const oneDayInSeconds = 24 * 60 * 60;

export class translationConfigService {

    private static instance: translationConfigService;
    private requestClient: apiClient

    private basicConfigOptionsPromise: Promise<BasicConfigOptions> | null = null;
    private userConfigOptionsPromise: Promise<UserConfigOptions> | null = null;

    private constructor() {
        this.requestClient = new apiClient();
    }

    public static getInstance(): translationConfigService {
        if (!translationConfigService.instance) {
            translationConfigService.instance = new translationConfigService();
        }
        return translationConfigService.instance;
    }

    static clearCache() {
        this.removeSubSubjectConfigOptionsFromCache();
        this.removeBasicConfigOptionsFromCache();
        this.removeUserConfigOptionsFromCache();
    }

    async getSubSubjectOptions(subsubjectId: number) {
        const key = `s-${subsubjectId}`;

        let cacheOptions = this.getSubSubjectOptionsFromCache();
        if (cacheOptions !== null) {
            if (!(key in cacheOptions)) {

                const dbOptions = await this.requestClient.getSubSubjectOptions(subsubjectId)
                cacheOptions[key] = dbOptions
                this.setSubSubjectConfigOptionsToCache(cacheOptions);
                return cacheOptions[key];
            }

            this.setSubSubjectConfigOptionsToCache(cacheOptions);
            return cacheOptions[key];
        }

        const dbOptions = await this.requestClient.getSubSubjectOptions(subsubjectId)
        cacheOptions = {}
        cacheOptions[key] = dbOptions;
        this.setSubSubjectConfigOptionsToCache(cacheOptions);
        return cacheOptions[key];
    }

    private getSubSubjectOptionsFromCache(): any{
        return clientCache.get('subSubjectConfigOptions');
    }

    private setSubSubjectConfigOptionsToCache(value: any) {
        clientCache.set('subSubjectConfigOptions', value, oneDayInSeconds);
    }

    static removeSubSubjectConfigOptionsFromCache() {
        clientCache.remove('subSubjectConfigOptions');
    }

    public async getConfigOptions(): Promise<ConfigurationOptions>  {

        const basicConfigOptions = await this.getBasicConfigOptions();
        const userConfigOptions = await this.getUserConfigOptions();

        const result: ConfigurationOptions = {
            databaseTypes: basicConfigOptions.databaseTypes,
            subjects: basicConfigOptions.subjects,
            languages: userConfigOptions?.languages,
            projectCodes: userConfigOptions?.projectCodes,
        }

        return result;
    }

    private async getBasicConfigOptions(): Promise<BasicConfigOptions> {
        if (this.basicConfigOptionsPromise) {
            return this.basicConfigOptionsPromise;
        }

        this.basicConfigOptionsPromise = (async () => {
            const cacheOptions = this.getBasicConfigOptionsFromCache();
            if (cacheOptions !== null) {
                this.setBasicConfigOptionsToCache(cacheOptions);
                return cacheOptions;
            }

            const dbOptions = await this.requestClient.basicConfigOptions() as BasicConfigOptions;
            this.setBasicConfigOptionsToCache(dbOptions);
            return dbOptions;
        })();

        return this.basicConfigOptionsPromise;
    }

    private getBasicConfigOptionsFromCache(): BasicConfigOptions {
        return clientCache.get('basicConfigOptions');
    }


    private setBasicConfigOptionsToCache(value: BasicConfigOptions) {
        clientCache.set('basicConfigOptions', value, oneDayInSeconds);
    }

    static removeBasicConfigOptionsFromCache () {
        clientCache.remove('basicConfigOptions');
    }

    private async getUserConfigOptions(): Promise<UserConfigOptions> {
        if (this.userConfigOptionsPromise) {
            return this.userConfigOptionsPromise;
        }

        this.userConfigOptionsPromise = (async () => {
            const cacheOptions = this.getUserConfigOptionsFromCache();
            if (cacheOptions !== null) {
                this.setUserConfigOptionsToCache(cacheOptions);
                this.userConfigOptionsPromise = null;
                return cacheOptions;
            }

            const dbOptions = await this.requestClient.userConfigOptions() as UserConfigOptions;
            this.setUserConfigOptionsToCache(dbOptions);
            this.userConfigOptionsPromise = null;
            return dbOptions;
        })();

        return this.userConfigOptionsPromise;
    }


    public async getExternalTranslationProviders(sourceLanguageId: number, tergetLanguageId: number): Promise<Array<IdNamePair>> {
        const providers = await this.requestClient.getExternalTranslationProviders(sourceLanguageId, tergetLanguageId);
        return providers;
    }

    private getUserConfigOptionsFromCache(): UserConfigOptions {
        return clientCache.get('userConfigOptions');
    }

    private setUserConfigOptionsToCache(value: UserConfigOptions) {
        clientCache.set('userConfigOptions', value, oneDayInSeconds);
    }

    static removeUserConfigOptionsFromCache() {
        clientCache.remove('userConfigOptions');
    }
}
