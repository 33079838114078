import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "locale-changer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_ctx.multiLingialAvailable)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_select, {
          modelValue: _ctx.$i18n.locale,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
          onChange: _ctx.setLanguage
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.langs, (lang, i) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: `Lang${i}`,
                value: lang,
                label: lang
              }, null, 8, ["value", "label"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"])
      ]))
    : _createCommentVNode("", true)
}