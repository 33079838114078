import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "home-page-tabs",
  class: "home-page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserDocuments = _resolveComponent("UserDocuments")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_ArchiveDocuments = _resolveComponent("ArchiveDocuments")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeTabName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTabName) = $event)),
      type: "border-card"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          name: "userDocuments",
          lazy: "",
          label: _ctx.$t('home.continueTranslation')
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_UserDocuments, {
              key: _ctx.updateUpload,
              onGoFileUpload: _ctx.goFileUpload,
              onDocumentAchived: _ctx.documentAchived,
              onDocumentDeleted: _ctx.documentDeleted
            }, null, 8, ["onGoFileUpload", "onDocumentAchived", "onDocumentDeleted"]))
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_tab_pane, {
          name: "fileUpload",
          lazy: "",
          label: _ctx.$t('home.newFile')
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_FileUpload, {
              key: _ctx.updateUpload,
              onUploadError: _ctx.uploadError
            }, null, 8, ["onUploadError"]))
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_tab_pane, {
          name: "archiveDocuments",
          lazy: "",
          label: _ctx.$t('home.archive')
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_ArchiveDocuments, { key: _ctx.updateArchive }))
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}