import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "shortcut-button",
  class: "img-button-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_UserTour = _resolveComponent("UserTour")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_popover, {
      placement: "bottom",
      width: "850",
      trigger: "click"
    }, {
      reference: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_tooltip, {
            content: _ctx.tooltip,
            placement: "bottom"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("span", { class: "material-symbols-outlined" }, " info ", -1)
            ])),
            _: 1
          }, 8, ["content"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_tabs, {
            modelValue: _ctx.activeTab,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tab_pane, {
                label: _ctx.$t('helpInfo.keybordShortcuts'),
                name: "shortcuts"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table, {
                    data: _ctx.tableData,
                    style: {"width":"100%"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, {
                        prop: "win",
                        label: "Win",
                        width: "180"
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "mac",
                        label: "Mac",
                        width: "180"
                      }),
                      _createVNode(_component_el_table_column, {
                        label: _ctx.$t('helpInfo.action')
                      }, {
                        default: _withCtx((scope) => [
                          _createTextVNode(_toDisplayString(_ctx.$t(scope.row.actionKey)), 1)
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }, 8, ["data"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_tab_pane, {
                label: _ctx.$t('helpInfo.tours'),
                name: "tours"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_UserTour)
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]),
      _: 1
    })
  ]))
}