import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "translation-load-container" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_button, {
      type: "primary",
      disabled: !_ctx.serviceAvailable,
      onClick: _ctx.clickAction
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('externalTranslations.loadExternalTranslation')), 1)
      ]),
      _: 1
    }, 8, ["disabled", "onClick"]),
    (!_ctx.serviceAvailable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('externalTranslations.externalTranslationServiceNotAvailable')), 1))
      : _createCommentVNode("", true)
  ]))
}