import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "find-next-segmet-container" }
const _hoisted_2 = {
  id: "find-next-segmet-container",
  class: "img-circle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showMoveToNextWorkSegment)
      ? (_openBlock(), _createBlock(_component_el_tooltip, {
          key: 0,
          placement: "top-start",
          content: _ctx.nextTranslateSegmentTooltip,
          "show-after": 5
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.nextTranslateSegment && _ctx.nextTranslateSegment(...args))),
                class: "img-button-container"
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("span", { class: "material-symbols-outlined" }, " move_down ", -1)
              ]))
            ])
          ]),
          _: 1
        }, 8, ["content"]))
      : _createCommentVNode("", true)
  ]))
}