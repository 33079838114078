import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table-name" }
const _hoisted_2 = { class: "columns" }
const _hoisted_3 = { class: "result-table" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('externalTranslations.externalTranslation')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('externalTranslations.provider')), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('externalTranslations.translation')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.externalTranslationVariants, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'ext' + index
        }, [
          _createElementVNode("div", {
            class: "variant-container",
            onClick: ($event: any) => (_ctx.setDraftValue(item))
          }, [
            _createElementVNode("div", null, _toDisplayString(_ctx.providerName(item)), 1),
            _createElementVNode("div", null, _toDisplayString(item.translation), 1)
          ], 8, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}