<template>
    <div id="merge-control"
         class="merge-control">

        <div class="merge-header">

            <div class="control-name">
                {{ wrapperTitle }}
            </div>

            <div class="close-icon"
                 @click="closeMergeAction">

                <el-tooltip placement="top"
                            :content="discardTooltip"
                            :show-after="10">

                    <span class="material-symbols-outlined"> close </span>

                </el-tooltip>
            </div>
        </div>

        <slot>

        </slot>

        <div class="footer-actions">

            <el-tooltip placement="top"
                        :content="saveTooltip"
                        :show-after="10">
                <el-button id="save-merge"
                           type="success"
                           @click="saveMergeAction">
                    {{$t('generic.save')}}
                </el-button>
            </el-tooltip>

            <el-tooltip placement="top"
                        :content="discardTooltip"
                        :show-after="10">
                <el-button id="cancel-merge"
                           type="danger"
                           @click="closeMergeAction">
                    {{$t('generic.cancel')}}
                </el-button>
            </el-tooltip>

        </div>


    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { useI18n } from 'vue-i18n'

    import { shortcuts } from '@/utils/shortcuts';
    import { notificationService } from '@/services/notificationService';

    export default defineComponent({

        props: {
            wrapperTitle : String,
        },
        emits: [
            "cancel",
            "confirm",
        ],
        data() {
            return {
                notificatioService: new notificationService(),
                saveTooltip: shortcuts.getTooltipTranslated(this.$t, shortcuts.saveMerge),
                discardTooltip: shortcuts.getTooltipTranslated(this.$t, shortcuts.discardMerge),
            };
        },
        mounted() {
            document.addEventListener("keydown", this.hotkeysListener);
        },
        beforeUnmount() {
            document.removeEventListener("keydown", this.hotkeysListener);
        },
        setup(){
            const { t } = useI18n()
            return { t }
        },
        methods: {
            async saveMergeAction() {
                this.$emit('confirm');
            },
            async closeMergeAction() {
                this.$emit('cancel');
            },
            async hotkeysListener(e: KeyboardEvent) {

                if (e.key === "s" && e.ctrlKey) {
                    e.preventDefault()
                    await this.saveMergeAction();
                    this.notificatioService.succes(this.t('mergeSegment.submitted'), this.t('mergeSegment.segmentsSaved'));
                    return
                }

                if (e.key === "x" && e.ctrlKey) {
                    await this.closeMergeAction();
                    this.notificatioService.info(this.t('mergeSegment.cancelled'), this.t('mergeSegment.actionIsCancelled'));
                    return
                }
            },
        },
    });
</script>

<style lang="scss" scoped>
    .merge-control {
        margin-bottom: 10px;
        padding: 10px;
        background: #e9e9e9;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: 100%;
        box-sizing: border-box;

        .merge-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;

            .control-name {
                margin: 10px;
                font-size: 18px;
                font-weight: 600;
            }
        }

        .remove-icon,
        .close-icon {
            cursor: pointer;
            opacity: 0.6;
            transition: 0.3s;
            color: red;
        }

        .add-line {
            cursor: pointer;
            opacity: 0.6;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: space-around;
            color: green;
            margin-bottom: 12px;
        }

        .title {
            display: flex;
            margin: 10px 5px;
        }


        .empty-target {
            border: solid 1px #cdcccc;
            width: 500px;
            height: 50px;
            margin-top: 12px;
        }

        .footer-actions {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
        }
    }
</style>