import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("link", {
      href: "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined",
      rel: "stylesheet"
    }, null, -1)),
    _cache[1] || (_cache[1] = _createElementVNode("link", {
      rel: "stylesheet",
      href: "https://cdn.jsdelivr.net/npm/quill@2.0.3/dist/quill.bubble.css"
    }, null, -1)),
    _createVNode(_component_router_view)
  ], 64))
}