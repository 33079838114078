<template>
    <i></i>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { useI18n } from 'vue-i18n'

    import { notificationService } from '@/services/notificationService';
    import { translationSegmentService } from './TranslationSegment/translationSegmentService';
    import { segmentsEditorService } from './segmentsEditorService';

    export default defineComponent({
        data() {
            return {
                notificationService: new notificationService(),
                segmentsEditorService: new segmentsEditorService(),
                translationSegmentService: translationSegmentService.getInstance(),
            }
        },
        setup() {
            const { t } = useI18n()
            return { t }
        },
        mounted() {
            document.addEventListener("keydown", this.hotkeysListener);
        },
        beforeUnmount() {
            document.removeEventListener("keydown", this.hotkeysListener);
        },
        methods: {
            async hotkeysListener(e: KeyboardEvent) {

                if (e.altKey && e.key === "c") {
                    await this.translationSegmentService.copyCurrentSegment();
                    this.notificationService.succes(this.t('hotkeys.copied'), this.t('hotkeys.sourceCopied'));
                    return
                }

                if (e.altKey && e.key === "t") {
                    await this.translationSegmentService.translateCurrentSegment();
                    this.notificationService.succes(this.t('hotkeys.translated'), this.t('hotkeys.variantsLoadded'));
                    return
                }

                if (e.altKey && e.key === "s") {
                    await this.doAddTranslationWithNotification();
                    return
                }

                if (e.altKey && e.key === "u") {
                    await this.doUpdateTranslationWithNotification();
                    return
                }

                if (e.altKey && e.key === "Backspace") {
                    await this.translationSegmentService.cleanCurrentSegmentTranslationSegment();
                    this.notificationService.succes(this.t('hotkeys.cleared'), this.t('hotkeys.segmentCleared'));
                    return
                }
                               
                if (e.altKey && e.key === "q") {
                    this.translationSegmentService.removeStylesInCurrentSrgment();
                    this.notificationService.succes(this.t('hotkeys.stylesRemoved'), "");
                    return
                }

                if (e.altKey && e.key === "v") {
                    this.translationSegmentService.toogleCurrentSegmentVariantsPopup();
                    return
                }   
                
                if (e.shiftKey && e.key === "Tab") {
                    e.preventDefault();
                    this.segmentsEditorService.goToPreviousSegment();
                    return
                }

                if (e.key === "Tab") {
                    e.preventDefault();
                    this.segmentsEditorService.goToNextSegment();
                    return
                }

                if (e.altKey && e.key === "PageUp") {
                    this.segmentsEditorService.goToNextPage();
                    return
                }  
                
                if (e.altKey && e.key === "PageDown") {
                    this.segmentsEditorService.goToPreviousPage();
                    return
                }

                if (e.altKey && e.key === "ArrowRight") {
                    e.preventDefault();
                    await this.doAddTranslationWithNotification();
                    this.segmentsEditorService.goToNextSegment();
                    return
                } 

                if (e.altKey && e.key === "ArrowLeft") {
                    e.preventDefault();
                    await this.doAddTranslationWithNotification();
                    this.segmentsEditorService.goToPreviousSegment();
                    return
                }
                
                if (e.ctrlKey && e.key === "m") {
                    const currentSegment = this.segmentsEditorService.getCurrentSegmentEditorItem();
                    
                    if (this.translationSegmentService.mergeIsPossible(currentSegment) == false){
                        return;
                    }

                    this.segmentsEditorService.statrMerge(currentSegment.itemId);
                    this.notificationService.info(this.t('hotkeys.mergeBegin'), this.t('hotkeys.mergeDialog'))
                    return
                }

                if (e.ctrlKey && e.shiftKey && e.key === "S") {
                    const currentSegment = this.segmentsEditorService.getCurrentSegmentEditorItem();
                    
                    if (this.translationSegmentService.splitIsPossible(currentSegment) == false){
                        return;
                    }

                    this.segmentsEditorService.statrSplit(currentSegment.itemId);
                    this.notificationService.info(this.t('hotkeys.splitBegin'), this.t('hotkeys.splitDialog'))
                    return
                }
                
                if (e.altKey && e.key === "n") {
                    this.notificationService.info(this.t('hotkeys.nextWorkingSegmnet'), "");
                    await this.segmentsEditorService.goToNextWorkSegment();
                    return
                }
            },
            
            async doAddTranslationWithNotification() {
                if(this.translationSegmentService.translationCouldBeAdded()){
                    await this.translationSegmentService.addCurrentSegmentTranslation();
                    this.notificationService.succes(this.t('hotkeys.saved'), this.t('hotkeys.translationSaved'));
                }
            },

            async doUpdateTranslationWithNotification(){
                if(this.translationSegmentService.translationCouldBeUpdated()){
                    await this.translationSegmentService.updateCurrentSegmentTranslation();
                    this.notificationService.succes(this.t('hotkeys.updated'), this.t('hotkeys.translationUpdated'));
                }
            },
        }
    })

</script>