import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tittle"
}
const _hoisted_2 = { class: "file-loader" }
const _hoisted_3 = ["accept", "disabled"]
const _hoisted_4 = { class: "config-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StorageStatusInformation = _resolveComponent("StorageStatusInformation")!
  const _component_TranslationConfig = _resolveComponent("TranslationConfig")!
  const _component_FileUploadPrompt = _resolveComponent("FileUploadPrompt")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_StorageStatusInformation, { documnetsCount: _ctx.documentCount }, null, 8, ["documnetsCount"]),
    _createElementVNode("div", null, [
      (!_ctx.uploadDisabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.tittle), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, [
          _createTextVNode(_toDisplayString(_ctx.$t('fileUpload.file')) + " ", 1),
          _createElementVNode("input", {
            type: "file",
            id: "file-upload",
            ref: "file",
            accept: _ctx.acceptedTypes,
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFileUpload())),
            disabled: _ctx.uploadDisabled
          }, null, 40, _hoisted_3)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_TranslationConfig, {
          modelValue: _ctx.configuration,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.configuration) = $event)),
          pageNamePrefix: _ctx.$t('fileUpload.configurationPrefix'),
          expended: true,
          excelConfigEditable: true,
          documentType: _ctx.documentType,
          onConfigChanged: _ctx.saveConfig
        }, null, 8, ["modelValue", "pageNamePrefix", "documentType", "onConfigChanged"])
      ]),
      _createVNode(_component_FileUploadPrompt, {
        submitDisabled: _ctx.submitDisabled,
        onSubmitted: _ctx.submitFile
      }, null, 8, ["submitDisabled", "onSubmitted"])
    ])
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}