<template>
    <el-col :span="6"
            id="use-external-translations">
        <p>
            {{ $t('translationConfig.useExternalTranslation') }}
        </p>
        <el-checkbox v-model="configuration.useExternalTranslation"
                     @change="valueChanged"
                     size="large"
                     :disabled="disabled" />
    </el-col>

    <el-col :span="1"></el-col>

    <el-col :span="5"
            id="translation-providers">
        <p>
            {{ $t('translationConfig.translationProvider') }}
        </p>
        <el-select v-model="configuration.externalTranslationProviders"
                   :multiple-limit="1"
                   multiple
                   @change="valueChanged"
                   placeholder="Provider not selected"
                   :disabled="externalProvidersDisabled">

            <el-option v-for="item in externalProvidersOptions"
                       :key="item.id"
                       :label="`${item.name}`"
                       :value="item.id" />

            <template #empty>
                <div>
                    {{ $t('translationConfig.noProviderSupportThisLanguages') }}
                </div>
            </template>

        </el-select>
    </el-col>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { TranslationConfiguration } from '@/models/TranslationConfiguration'
    import { IdNamePair } from '@/models/ConfigurationOptions';

    import debounce from "lodash.debounce";
    import { translationConfigService } from '../translationConfigService'
    import { notificationService } from '@/services/notificationService';

    export default defineComponent({
        props: {
            modelValue: Object,
            disabled: {
                type: Boolean,
                defaultValue: false
            },
        },
        emits: ['input', 'valueChanged'],
        data() {
            return {
                translationConfigService: translationConfigService.getInstance(),
                notificationService: new notificationService(),

                debouncedExternalProvider: null as any,

                externalProvidersOptions: [] as IdNamePair[],

                providersAreNotAvailable: this.$t('translationConfig.providersAreNotAvailable'),
                noExternalProviders: this.$t('translationConfig.noExternalProviders')
            }
        },
        created() {
            this.debouncedExternalProvider = debounce(() => {
                this.loadExternalProviders();
            }, 1500);
        },
        beforeUnmount() {
            this.debouncedExternalProvider.cancel();
        },
        mounted() {
            this.loadExternalProviders();
        },
        watch: {
            async 'configuration.sourceLanguageId'(newVal) {
                if (newVal != null) {
                    await this.debouncedExternalProvider();
                }
            },
            async 'configuration.targetLanguageId'(newVal) {
                if (newVal != null) {
                    await this.debouncedExternalProvider();
                }
            },
            async 'configuration.useExternalTranslation'(newVal) {
                if (newVal == true) {
                    await this.debouncedExternalProvider();
                }
                if (newVal == false) {
                    this.clearExternalProviders();
                }
            },
        },
        computed: {
            configuration: {
                get(): TranslationConfiguration { return this.modelValue as TranslationConfiguration; },
                set(value: TranslationConfiguration) { this.$emit('input', value); }
            },
            externalProvidersDisabled(): boolean {
                return this.disabled || !this.configuration.useExternalTranslation;
            },
        },
        methods: {
            async loadExternalProviders() {
                if (this.configuration.useExternalTranslation) {
                    var providers = await this.translationConfigService
                        .getExternalTranslationProviders(this.configuration.sourceLanguageId, this.configuration.targetLanguageId)

                    this.externalProvidersOptions = providers;

                    if(this.configuration.externalTranslationProviders.length > 0){
                        
                        const providerIds = this.externalProvidersOptions.map(pr => pr.id)
                        if(!providerIds.includes(this.configuration.externalTranslationProviders[0])){
                            this.notificationService.info(this.providersAreNotAvailable, "");
                            this.clearExternalProviders();
                            this.valueChanged();                    
                        }
                    } 

                    if (providers.length == 0) {
                        this.notificationService.info(this.noExternalProviders, "");
                        this.clearExternalProviders();
                        this.valueChanged();
                    }

                }
            },
            clearExternalProviders() {
                this.configuration.externalTranslationProviders = [];
            },
            valueChanged() {
                this.$emit('valueChanged');
            },
        }
    })
</script>
