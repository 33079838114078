import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_tour_step = _resolveComponent("el-tour-step")!
  const _component_el_tour = _resolveComponent("el-tour")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.newUserDialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newUserDialogVisible) = $event)),
      title: _ctx.newUserTittle,
      width: "30%",
      "align-center": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_button, {
            onClick: _ctx.neverShowTours,
            type: "info"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Newer show this dialog")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createElementVNode("div", null, [
            _createVNode(_component_el_button, {
              onClick: _ctx.startTour,
              type: "primary"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Quick start")
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_el_button, { onClick: _ctx.skipTourToday }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Next time")
              ])),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.newUserText), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_el_tour, {
      modelValue: _ctx.tourStart,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tourStart) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tourSteps, (step) => {
          return (_openBlock(), _createBlock(_component_el_tour_step, {
            key: step.id,
            target: step.target,
            title: step.title,
            description: step.description
          }, null, 8, ["target", "title", "description"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}