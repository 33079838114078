<template>
    <div class="translation-load-container">
        <el-button type="primary"
                   :disabled="!serviceAvailable"
                   @click="clickAction">
            {{$t('externalTranslations.loadExternalTranslation')}}
        </el-button>
        <div v-if="!serviceAvailable">
            {{$t('externalTranslations.externalTranslationServiceNotAvailable')}}
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            serviceAvailable: {
                type: Boolean,
                defaultValue: false
            },
        },
        methods: {
            clickAction() {
                this.$emit('loadButtonClick');
            },
        }
    })

</script>

<style scoped lang="scss">
    .translation-load-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & > div {
            padding: 5px;
        }
    }
</style>