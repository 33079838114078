import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "documents"
}
const _hoisted_3 = { class: "file-actions" }
const _hoisted_4 = { class: "dialog-footer" }
const _hoisted_5 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_FileType = _resolveComponent("FileType")!
  const _component_ProgressTags = _resolveComponent("ProgressTags")!
  const _component_DocumentState = _resolveComponent("DocumentState")!
  const _component_EditButton = _resolveComponent("EditButton")!
  const _component_FileActions = _resolveComponent("FileActions")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('userDocuments.tittle')), 1),
    (_ctx.noDocuments)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_el_empty, {
            description: _ctx.$t('userDocuments.noDocuments')
          }, null, 8, ["description"]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.goToUploadFile
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('userDocuments.goToUpload')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])), [
          [_directive_loading, _ctx.loading]
        ])
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives((_openBlock(), _createBlock(_component_el_table, { data: _ctx.documents }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "id",
                label: "Id",
                width: "50"
              }),
              _createVNode(_component_el_table_column, {
                width: "100",
                prop: "documentType",
                label: _ctx.$t('userDocuments.documentType')
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_FileType, {
                    document: scope.row
                  }, null, 8, ["document"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                prop: "name",
                label: _ctx.$t('userDocuments.name')
              }, null, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('userDocuments.language')
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(_ctx.languages(scope.row)), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('userDocuments.createdOn')
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(_ctx.mapDate(scope.row.createdAt)), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('userDocuments.updatedOn')
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(_ctx.mapDateTime(scope.row.updatedAt)), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('userDocuments.progress')
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_ProgressTags, {
                    rowData: scope.row
                  }, null, 8, ["rowData"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('userDocuments.state')
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_DocumentState, {
                    rowData: scope.row
                  }, null, 8, ["rowData"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                fixed: "right",
                label: _ctx.$t('userDocuments.operations')
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_EditButton, {
                      rowData: scope.row
                    }, null, 8, ["rowData"]),
                    _createVNode(_component_FileActions, {
                      rowData: scope.row,
                      onArchiveAction: _ctx.archiveDialog,
                      onDeleteAction: _ctx.deleteDialog
                    }, null, 8, ["rowData", "onArchiveAction", "onDeleteAction"])
                  ])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["data"])), [
            [_directive_loading, _ctx.loading]
          ]),
          _createVNode(_component_el_pagination, {
            layout: "prev, pager, next",
            total: _ctx.rowCount,
            "default-page-size": _ctx.pageSize,
            onCurrentChange: _ctx.goToPage
          }, null, 8, ["total", "default-page-size", "onCurrentChange"])
        ])),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.archiveDialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.archiveDialogVisible) = $event)),
      title: _ctx.$t('userDocuments.archve'),
      width: "30%",
      "align-center": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_el_button, { onClick: _ctx.closeArchiveDialog }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("generic.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.archiveConfirm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("generic.confirm")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("userDocuments.archveMessage")), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.deleteDialogVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deleteDialogVisible) = $event)),
      title: _ctx.$t('fileActions.delete'),
      width: "30%",
      "align-center": ""
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", _hoisted_5, [
          _createVNode(_component_el_button, { onClick: _ctx.closeDeleteDialog }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("generic.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.deleteConfirm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("generic.confirm")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("userDocuments.deleteMessage")), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}