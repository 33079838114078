<template >
    <div class="tags">
        <div :class="className">
            {{tagText}}
        </div>
        <div v-if="coldNotBeSaved"
             class="tag">
            #too-long   
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { TranslationSegment } from '@/models/TranslationSegment/TranslationSegment';
    import { TranslationState } from '@/models/TranslationSegment/TranslationState';
    import { SegmentStatus } from '@/models/TranslationSegment/SegmentStatus';

    import { segmentTextLogicService } from '@/services/translationSegment/segmentTextLogicService';

    export default defineComponent({
        props: {
            modelValue: Object as PropType<TranslationSegment>,
        },
        data() {
            return {
                segmentTextLogicService: segmentTextLogicService.getInstance(),
                className: 'hidden',
                tagText: '',

                emptyTag: '',
                editTag: this.$t('segmentState.edit'),
                styleChangedTag: this.$t('segmentState.styleChanged'),
                fuzzyMatchTag: this.$t('segmentState.fuzzyMatch'),
                draftTag: this.$t('segmentState.draft'),
                autoTag: this.$t('segmentState.auto'),
            }
        },
        watch: {
            modelValue: {
                immediate: true,
                deep: true,
                handler() {
                    this.init();
                }
            }
        },
        computed: {
            translationSegment(): TranslationSegment {
                return this.modelValue as TranslationSegment;
            },
            coldNotBeSaved(): boolean {
                return this.translationSegment.segmentStatus == SegmentStatus.TargetReach500Limit;
            }
        },
        methods: {
            init() {
                switch (this.translationSegment.translationState) {
                    case TranslationState.Draft:
                        if (this.segmentTextLogicService.perfectMatch(this.translationSegment)) {
                            this.edit();
                            return;
                        } else {
                            this.draft();
                            return;
                        }
                    case TranslationState.PreTranslated:
                        this.fuzzyMatch();
                        return 
                    case TranslationState.Translated:

                        if (!this.segmentTextLogicService.drftStyleEqalTranslationStyle(this.translationSegment)){
                            this.styleChanged();
                            return;
                        } else {
                            this.hide();
                            return;
                        }
                    case TranslationState.AutoTranslated:
                        this.autoTranslated();
                        return;
                    default:
                        this.hide();
                        return 
                }

            },
            hide() { 
                this.className = 'hidden'
                this.tagText = this.emptyTag;
            },
            edit() {
                this.className = 'tag';
                this.tagText = this.editTag;
            },
            styleChanged() {
                this.className = 'tag';
                this.tagText = this.styleChangedTag;
            },
            fuzzyMatch() {
                this.className = 'tag';
                this.tagText = this.fuzzyMatchTag;
            },
            draft() {
                this.className = 'tag';
                this.tagText = this.draftTag;
            },
            autoTranslated() {
                this.className = 'tag';
                this.tagText = this.autoTag;
            }
        }

    });
</script>

<style lang="scss" scoped>
    .tags {
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
    }

    .tag {
        padding: 0 10px;
        height: 16px;
        font-size: 14px;
        border-radius: 25px;
        font-weight: 600;
        background-color: #CECECE;
        color: #7f7d7d;
        display: flex;
        align-items: center;
        margin-left: 3px;
    }

    .hidden {
        opacity: 0;
    }

</style>
