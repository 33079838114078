<template>

    <el-dropdown @click="pageTranslationDialogVisible = true"
                 size="default"
                 split-button
                 type="primary">
        {{$t('translatePage.buttonTranslatePage')}}
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item @click="documentTranslationDialogVisible = true">
                    {{$t('translatePage.buttonTranslateAllDocument')}}
                </el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>

    <el-dialog v-model="pageTranslationDialogVisible"
               :title="$t('translatePage.translatePageTitle')"
               width="40%"
               :show-close="false"
               align-center>
        <div class="message">
            {{$t('translatePage.translatePageMessage')}}
        </div>

        <el-collapse accordion>
            <el-collapse-item :title="$t('translatePage.externalTranslations')" name="1">
                <div>
                    <el-checkbox v-model="useExternalTranslation"
                                 :disabled="!externalTranslationProviderAvailable"
                                 :label="$t('translatePage.useExternalTranslations')"
                                 size="large" />

                    <el-alert type="success"
                              :class="{ hidden: !useExternalTranslation }"
                              :title="$t('translatePage.externalTranslationsMessage')"
                              :closable="false" />

                    <el-alert type="warning"
                              :class="{ hidden: externalTranslationProviderAvailable }"
                              :title="$t('translatePage.translationProviderNotConfigured')"
                              :closable="false" />


                </div>

            </el-collapse-item>
        </el-collapse>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closePageTranslateDialog">
                    {{$t('generic.cancel')}}
                </el-button>
                <el-button type="primary" @click="translatePage">
                    {{$t('generic.confirm')}}
                </el-button>
            </span>
        </template>
    </el-dialog>

    <el-dialog v-model="documentTranslationDialogVisible"
               :title="$t('translatePage.translateDocument')"
               width="40%"
               :show-close="false"
               align-center>
        <div class="message">
            {{$t('translatePage.translateDocumentMessage')}}
        </div>

        <el-collapse accordion>
            <el-collapse-item :title="$t('translatePage.externalTranslations')" name="1">
                <div>
                    <el-checkbox v-model="useExternalTranslation"
                                 :disabled="!externalTranslationProviderAvailable"
                                 :label="$t('translatePage.useExternalTranslations')"
                                 size="large" />

                    <el-alert type="success"
                              :class="{ hidden: !useExternalTranslation }"
                              :title="$t('translatePage.externalTranslationsMessage')"
                              :closable="false" />

                    <el-alert type="warning"
                              :class="{ hidden: externalTranslationProviderAvailable }"
                              :title="$t('translatePage.translationProviderNotConfigured')"
                              :closable="false" />
                </div>

            </el-collapse-item>
        </el-collapse>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closeDocumentTranslateDialog">
                    {{$t('generic.cancel')}}
                </el-button>
                <el-button type="primary" @click="translateAll">
                    {{$t('generic.confirm')}}
                </el-button>
            </span>
        </template>
    </el-dialog>

</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import { TranslationConfiguration } from '@/models/TranslationConfiguration';

    export default defineComponent({
        props: {
            configuration: Object as PropType<TranslationConfiguration>,
        },
        emits: ['translatePageConfirmed', 'translateAllConfirmed'],
        data() {
            return {
                pageTranslationDialogVisible: false,
                documentTranslationDialogVisible: false,
                useExternalTranslation: false,
            }
        },
        computed: {
            externalTranslationProviderAvailable(): boolean {
                return this.configuration != null && 
                    this.configuration.useExternalTranslation &&
                    this.configuration.externalTranslationProviders != null &&
                    this.configuration.externalTranslationProviders.length > 0;
            }
        },
        methods: {
            closePageTranslateDialog() {
                this.pageTranslationDialogVisible = false;
                this.useExternalTranslation = false;
            },
            closeDocumentTranslateDialog() {
                this.documentTranslationDialogVisible = false;
                this.useExternalTranslation = false;
            },
            translatePage() {
                this.$emit('translatePageConfirmed', this.useExternalTranslation);
                this.closePageTranslateDialog();
            },
            translateAll() {
                this.$emit('translateAllConfirmed', this.useExternalTranslation);
                this.closeDocumentTranslateDialog();
            },
        }
    })

</script>

<style lang="scss" scoped>

    .message {
        margin-bottom: 20px;
        font-size: 1.1em;
    }

    .hidden {
        display: none;
    }
</style>