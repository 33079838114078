import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "action-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "right",
    width: 150,
    trigger: "click"
  }, {
    reference: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "img-button-container" }, [
        _createElementVNode("span", { class: "material-symbols-outlined" }, " more_horiz ")
      ], -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.documentIsActive)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              onClick: _ctx.archiveAction,
              type: "warning",
              size: "small"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('fileActions.archive')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_button, {
          onClick: _ctx.deleteAction,
          type: "danger",
          size: "small"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('fileActions.delete')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}