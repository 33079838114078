<template>
    <div :class="[{'focused-item':focused}, 'merge-button-container']">

        <el-tooltip placement="top-start"
                    :content="megreTooltip"
                    :show-after="5">
            <div id="merge-segments"
                 class="img-button-container merge-button"
                 @click="mergeSegment">
                <span class="material-symbols-outlined">
                    compress
                </span>
            </div>
        </el-tooltip>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { shortcuts } from '@/utils/shortcuts';

    export default defineComponent({
        props: {
            focused: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                megreTooltip: shortcuts.getTooltipTranslated(this.$t, shortcuts.merge),
            }
        },
        methods: {
            mergeSegment() {
                this.$emit('merge');
            }
        }

    });
</script>

<style lang="scss" scoped>

    .merge-button-container {
        height: 1px;
        width: 30%;
        position: absolute;
        opacity: 0;
        transition: opacity 0.5s ease;

        &:hover {
            opacity: 0.6;
        }
    }

    .focused-item.merge-button-container {
        opacity: 0.6;
    }

    .merge-button {
        position: absolute;
        top: -35px;
        left: 10px;
    }


</style>