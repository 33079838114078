<template>
    <div>
        <div v-if="loaded">
            <BasePage>
                <UserHeader />
                <router-view />
            </BasePage>
        </div>
        <div class="loader" v-else>
            {{$t('generic.loading')}}...
            <div class="loading-item" v-loading="!loaded"> </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import BasePage from './BasePage.vue'
    import UserHeader from './UserHeader.vue'

    import { userService } from '@/services/userService'

    export default defineComponent({
        components: {
            BasePage,
            UserHeader,
        },

        data() {
            return {
                loaded: false,
                userService: new userService(),
                userName: '',
            };
        },
        mounted() {
            this.init();
        },

        methods: {
            async init() {
                this.loaded = false;

                try { 
                    this.userName = await this.userService.getUserName();
                }
                catch (error) {
                    this.loaded = false;
                    console.error(error);
                }

                this.loaded = true;
            }
        },
    })
</script>

<style scoped>
    .loader {
        margin-top: 100px;
    }
    .loading-item {
        margin-top: 30px;
    }
</style>