import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "segment-item" }
const _hoisted_2 = { class: "row-number" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SourceSegment = _resolveComponent("SourceSegment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.rowNumber), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_SourceSegment, {
        modelValue: _ctx.translationSegment.sourceData,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.translationSegment.sourceData) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_SourceSegment, {
        modelValue: _ctx.translationSegment.draftData,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.translationSegment.draftData) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}