<template>
    <div class="footer-basic">
        <ul class="list-inline">
            <li class="list-inline-item">
                <a target="_blank" :href="faqUrl">{{$t('pageNames.helpPage')}}</a>
            </li>
        </ul>

        <p class="copyright">WebWordSystem - {{versionTag}}</p>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        data() {
            return {
            };
        },
        computed: {
            production(): boolean {
                return process.env.NODE_ENV === 'production';
            },
            versionTag(): string {
                if (this.production) {
                    return process.env.VUE_APP_VERSION;
                }

                return `${process.env.VUE_APP_CURRENT_ENV} - ${process.env.VUE_APP_VERSION}`;
            },
            faqUrl(): string{
                return process.env.VUE_APP_FAQ_PAGE;
            },
        },
    })
</script>

<style lang="scss">
    .footer-basic {
        margin-bottom: 20px;
    }

    .footer-basic ul {
        padding: 0;
        list-style: none;
        text-align: center;
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 0;
    }

    .footer-basic li {
        padding: 0 10px;
    }

    .footer-basic ul a {
        color: inherit;
        text-decoration: none;
        opacity: 0.8;
    }

    .footer-basic ul a:hover {
        opacity: 1;
    }

    .footer-basic .copyright {
        margin-top: 15px;
        text-align: center;
        font-size: 13px;
        color: #aaa;
        margin-bottom: 0;
    }

</style>