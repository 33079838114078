import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_col = _resolveComponent("el-col")!

  return (_openBlock(), _createBlock(_component_el_col, {
    span: 6,
    id: "open-variants-popup"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('translationConfig.openVatiantsPopup')), 1),
      _createVNode(_component_el_checkbox, {
        modelValue: _ctx.configuration.openVariantsPopup,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.configuration.openVariantsPopup) = $event)),
        onChange: _ctx.valueChanged,
        size: "large",
        disabled: _ctx.disabled
      }, null, 8, ["modelValue", "onChange", "disabled"])
    ]),
    _: 1
  }))
}