<template>
    <div class="home-control">
        <el-button @click="goToHomePage"
                   type="primary">
            {{ $t('helpButtons.goHome') }}
        </el-button>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { navigationService } from '@/services/navigationService'

    export default defineComponent({
        methods: {
            goToHomePage() {
                navigationService.goHomePage()
            }
        }
    })
</script>

<style lang="scss" scoped>
    .home-control {
        margin-top: 40px;
    }
</style>