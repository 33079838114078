<template>
    <div>

        <el-popover placement="bottom"
                    width="850"
                    trigger="click"
                    >
            <template #reference>
                <div id="shortcut-button"
                     class="img-button-container">

                    <el-tooltip :content="tooltip"
                                placement="bottom">
                        <span class="material-symbols-outlined">
                            info
                        </span>
                    </el-tooltip>
                </div>
            </template>
            
            <div>

                <el-tabs v-model="activeTab">

                    <el-tab-pane :label="$t('helpInfo.keybordShortcuts')"
                                 name="shortcuts">
                        <el-table :data="tableData" style="width: 100%">
                            <el-table-column prop="win" 
                                             label="Win" 
                                             width="180" />
                            <el-table-column prop="mac" 
                                             label="Mac" 
                                             width="180" />
                            <el-table-column :label="$t('helpInfo.action')">
                                <template #default="scope">
                                    {{$t(scope.row.actionKey)}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>

                    <el-tab-pane :label="$t('helpInfo.tours')"
                                 name="tours">
                        <UserTour />
                    </el-tab-pane>

                </el-tabs>

            </div>

        </el-popover>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { shortcuts } from '@/utils/shortcuts';
    import UserTour from './UserTour/UserTour.vue'

    export default defineComponent({
        components: {
            UserTour,
        },
        data(){
            return {
                tableData: [] as any, 
                activeTab: 'shortcuts',
                tooltip: this.$t('helpInfo.helpInformation'),
            }
        },
        created(){
            this.tableData = [
                shortcuts.moveNext, 
                shortcuts.moveUp,
                shortcuts.saveMoveNext,
                shortcuts.saveMoveUp,
                shortcuts.goToNextPage,
                shortcuts.goToPreviousPage,
                shortcuts.copy,
                shortcuts.translate,
                shortcuts.addTranslation,
                shortcuts.updateTranslation,
                shortcuts.discardTranslation,
                shortcuts.clearStyles,
                shortcuts.variantsPopover,
                shortcuts.nextTranslateSegment,
                shortcuts.merge,
                shortcuts.split,
                shortcuts.addSegmentToMerge,
                shortcuts.removeSegmentToMerge,
                shortcuts.saveMerge,
                shortcuts.discardMerge,
            ]
        }
    })

</script>