import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer-basic" }
const _hoisted_2 = { class: "list-inline" }
const _hoisted_3 = { class: "list-inline-item" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createElementVNode("a", {
          target: "_blank",
          href: _ctx.faqUrl
        }, _toDisplayString(_ctx.$t('pageNames.helpPage')), 9, _hoisted_4)
      ])
    ]),
    _createElementVNode("p", _hoisted_5, "WebWordSystem - " + _toDisplayString(_ctx.versionTag), 1)
  ]))
}