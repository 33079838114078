import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, { placement: "top" }, {
    content: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageLines, (line, index) => {
        return (_openBlock(), _createElementBlock("span", { key: index }, [
          _createTextVNode(_toDisplayString(line) + " ", 1),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1))
        ]))
      }), 128))
    ]),
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "container" }, [
        _createElementVNode("i", { class: "limit-reached" })
      ], -1))
    ]),
    _: 1
  }))
}