import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "target-with-variants" }
const _hoisted_2 = { class: "tareget-container" }
const _hoisted_3 = { class: "header-row" }
const _hoisted_4 = { class: "time-stamp-wrapper" }
const _hoisted_5 = { class: "time-stamp" }
const _hoisted_6 = { class: "close-icon" }
const _hoisted_7 = {
  id: "variants-button",
  class: "variants-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslationVariants = _resolveComponent("TranslationVariants")!
  const _component_TargetSegmentEditor = _resolveComponent("TargetSegmentEditor")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_ButtonTemplate = _resolveComponent("ButtonTemplate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_popover, {
        visible: _ctx.focused && _ctx.showVariants,
        placement: "bottom",
        "show-arrow": false,
        width: 1160,
        "popper-class": "variants-popover"
      }, {
        reference: _withCtx(() => [
          _createElementVNode("div", {
            id: "tareget-container",
            class: _normalizeClass(['text-container', _ctx.translationStateClass, _ctx.focusedClass])
          }, [
            _createVNode(_component_TargetSegmentEditor, {
              modelValue: _ctx.translationSegment,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.translationSegment) = $event)),
              setFocus: _ctx.updateFocus,
              onFocus: _ctx.focusAction
            }, null, 8, ["modelValue", "setFocus", "onFocus"])
          ], 2)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "popover-name" }, [
              _createElementVNode("div", null, " Translation variants ")
            ], -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.variantsTimestamp), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeVariantsPopup && _ctx.closeVariantsPopup(...args))),
                  class: "material-symbols-outlined"
                }, " close ")
              ])
            ])
          ]),
          (_ctx.showVariants)
            ? (_openBlock(), _createBlock(_component_TranslationVariants, {
                key: 0,
                modelValue: _ctx.segmentItem,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.segmentItem) = $event)),
                configuration: _ctx.configuration,
                loading: _ctx.segmnetLoading
              }, null, 8, ["modelValue", "configuration", "loading"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_ButtonTemplate, {
        disabled: !_ctx.translationLoadded,
        tooltipMessage: _ctx.togleVariantsTooltip,
        onButtonClick: _ctx.doToogleVariantsPopup
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createElementVNode("span", { class: "material-symbols-outlined" }, " menu ", -1)
        ])),
        _: 1
      }, 8, ["disabled", "tooltipMessage", "onButtonClick"])
    ])
  ]))
}