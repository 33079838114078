import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "percentage-tag" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.translated)
      ? (_openBlock(), _createBlock(_component_el_tag, {
          key: 0,
          type: _ctx.type,
          effect: "plain",
          round: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.prercentage), 1)
          ]),
          _: 1
        }, 8, ["type"]))
      : _createCommentVNode("", true)
  ]))
}