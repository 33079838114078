import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'focused-item':_ctx.focused}, 'merge-button-container'])
  }, [
    _createVNode(_component_el_tooltip, {
      placement: "top-start",
      content: _ctx.megreTooltip,
      "show-after": 5
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          id: "split-button",
          class: "img-button-container merge-button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.splitSegment && _ctx.splitSegment(...args)))
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("span", { class: "material-symbols-outlined" }, " expand ", -1)
        ]))
      ]),
      _: 1
    }, 8, ["content"])
  ], 2))
}