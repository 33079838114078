<template>
    <div v-loading="loadingSegments">
        <template v-for="(item, index) in segmentsStore.renderSegments"
                  :key="getkey(item)">

            <component :is="componentType(segmentsStore.renderSegments[index])"
                       v-model="segmentsStore.renderSegments[index]"
                       @mergeSegment="mergeSegmentHandler"
                       @splitSegment="splitSegmentHandler" 
                       @nextTranslateSegment="nextTranslateSegmentHandler"
                       />
        </template>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    //store
    import { segmentsStore } from '@/stores/segmentsStore'
    import { pagingStore } from '@/stores/pagingStore'

    //service
    import { segmentsEditorService } from './segmentsEditorService'

    //types
    import { SegmentEditorItem } from '@/models/SegmentEditorItem';
    import { SegmentEditorItemType } from './SegmentEditorItemType';
    import { TranslationConfiguration } from '@/models/TranslationConfiguration';

    //components
    import TranslationSegment from './TranslationSegment/TranslationSegment.vue';
    import MergeSegment from './MergeSegment/MergeSegment.vue';
    import SplitSegment from './MergeSegment/SplitSegment.vue';

    export default defineComponent({
        props: {
            documentConfiguration: Object,
        },
        components: {
            TranslationSegment,
            MergeSegment,
            SplitSegment,
        },

        data() {
            return {
                segmentsStore: segmentsStore(),
                pagingStore: pagingStore(),

                segmentsEditorService: new segmentsEditorService(),

                loadingSegments: false,
            };
        },
        created() {
            this.segmentsStore.initStore();
        },

        watch: {
            documentConfiguration: {
                handler(){
                    this.initPageConfig();
                    this.loadCofiguredPage();
                },                
            },
            'pagingStore.reloadPage': {
                handler() {
                    this.loadDefaultPage();
                },
            },
            'pagingStore.reloadBack': {
                handler() {
                    this.loadPageBackwards();
                },
            },
            loadingSegments(newVal) {
                if (newVal == false) {
                    this.scrollToView();
                }

            }
        },
        computed: {
            documentId(): Number {
                return Number(this.$route.params.id);
            },
        },
        methods: {
            initPageConfig() {
                this.segmentsStore.setTranslationConfiguration(this.documentConfiguration?.translationConfig as TranslationConfiguration);

                if (this.documentConfiguration?.pageConfig) {
                    this.pagingStore.setPageNumber(this.documentConfiguration.pageConfig.pageIndex);
                    this.segmentsStore.currentSegmentIndex = this.documentConfiguration.pageConfig.rowIndex;
                }
            },

            scrollToView() {
                const element = document.querySelector(`.focused-row`);

                if (element) {
                    element.scrollIntoView({ behavior: 'auto', block: 'center' });
                }
            },

            async loadDefaultPage() {
                this.loadingSegments = true;
                this.initsegmentsStore();
                await this.segmentsEditorService.loadSegments(this.documentId);  
                this.segmentsEditorService.setFocusToSegment();
                this.loadingSegments = false;
            },
            
            
            async loadPageBackwards() {
                this.loadingSegments = true;                
                await this.segmentsEditorService.loadSegmentsWithFocusOnLast(this.documentId);           
                this.segmentsEditorService.setFocusToSegment();
                this.loadingSegments = false;
            },

            async loadCofiguredPage() {
                this.loadingSegments = true;
                await this.segmentsEditorService.loadSegments(this.documentId);
                this.segmentsEditorService.setFocusToSegment();
                this.loadingSegments = false;
            },

            initsegmentsStore() {
                this.segmentsStore.currentSegmentIndex = 0
            },

            getkey(segment: any) {
                return `s-${segment.data.translationSegmentId}`;
            },

            componentType(editorItem: SegmentEditorItem) {
                switch (editorItem.segmentType) {
                    case SegmentEditorItemType.TranslationSegment:
                        return TranslationSegment;
                    case SegmentEditorItemType.MergeSegment:
                        return MergeSegment;
                    case SegmentEditorItemType.SplitSegment:
                        return SplitSegment;
                }
            },

            mergeSegmentHandler(segmentId: number) {
                this.segmentsEditorService.statrMerge(segmentId);
            },

            splitSegmentHandler(segmentId: number) {
                this.segmentsEditorService.statrSplit(segmentId);
            },
            async nextTranslateSegmentHandler() {
                await this.segmentsEditorService.goToNextWorkSegment();
            },       
        },
    });
</script>

<style lang="scss" scoped>
    /* list transitions */
    .list-enter-from {
        opacity: 0;
        transform: translateY(40px);
    }

    .list-enter-active {
        transition: all 0.4s ease;
    }

    .list-leave-to {
        opacity: 0;
        transform: translateY(-40px);
    }

    .list-leave-active {
        transition: all 0.4s ease;
        position: absolute; /* for move transition after item leaves */
    }

    .list-move {
        transition: all 0.4s ease;
    }
</style>