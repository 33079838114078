<template>

    <el-button id="save-drafts"
               @click="saveDraftsDialogVisible = true"
               plain
               type="warning">
            {{$t('saveAllDrafts.saveAllDraftsButton')}}
        <div class="bttn-img">
            <span class="material-symbols-outlined">
                add_comment
            </span>
        </div>
    </el-button>

    <el-dialog v-model="saveDraftsDialogVisible"
               :title="$t('saveAllDrafts.saveAllDraftsPopup')"
               width="30%"
               align-center>
        <div class="message" v-html="$t('saveAllDrafts.saveDrftsMessageHtml')"></div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="closeSaveDraftsDialog">
                    {{$t('generic.cancel')}}
                </el-button>
                <el-button type="primary" @click="saveAllDrafts">
                    {{$t('generic.confirm')}}
                </el-button>
            </span>
        </template>
    </el-dialog>

</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        emits: ['saveConfirmed'],
        data() {
            return {
                saveDraftsDialogVisible: false,
            }
        },
        methods: {
            saveAllDrafts() {
                this.$emit('saveConfirmed');
                this.closeSaveDraftsDialog();
            },
            closeSaveDraftsDialog() {
                this.saveDraftsDialogVisible = false;
            },
        }
    })
</script>

<style lang="scss" scoped>
    .bttn-img {
        margin-left: 10px;
    }

    .message {
        font-size: 1.1em;
    }
</style>
