<template>
    <el-tooltip placement="top">
        <template #content>
            <span v-for="(line, index) in messageLines"
                  :key="index">
                {{line}}
                <br />
            </span>
        </template>
        <div class="container">
            <i class="limit-reached" />
        </div>

    </el-tooltip>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        data() {
            return {
                messageLines: [
                    this.$t('statusMessage.lineOne'),
                    this.$t('statusMessage.lineTwo'),
                ]
            }
        }
    })

</script>

<style scoped>
    .container {
        width: 24px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .limit-reached {
        background-image: url('@/images/alert.svg');
        width: 24px;
        height: 22px;
        background-repeat: no-repeat;
    }
</style>