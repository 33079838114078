import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "title-value" }
const _hoisted_2 = {
  key: 0,
  class: "limit-container"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_collapse, {
        modelValue: _ctx.collapseModel,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.collapseModel) = $event)),
        onChange: _ctx.handleChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_collapse_item, { name: "1" }, {
            title: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "img-container" }, [
                _createElementVNode("span", { class: "material-symbols-outlined" }, " equalizer ")
              ], -1)),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.tittle), 1),
              (_ctx.limitReached)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_el_tooltip, {
                      placement: "top",
                      content: _ctx.tooltipMessage
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createElementVNode("div", { class: "img-container" }, [
                          _createElementVNode("i", { class: "limit-reached" })
                        ], -1)
                      ])),
                      _: 1
                    }, 8, ["content"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_descriptions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_descriptions_item, {
                    label: _ctx.$t('documentStatistics.documentsName')
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.documentName), 1)
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  (_ctx.documentInformation.pageCount)
                    ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
                        key: 0,
                        label: _ctx.pageCountLabel
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.documentInformation.pageCount), 1)
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (_ctx.documentInformation.wordCount)
                    ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
                        key: 1,
                        label: _ctx.$t('documentStatistics.wordCount')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.documentInformation.wordCount), 1)
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              (_ctx.limitReached)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('documentStatistics.segmentsThatReachedLimit')), 1),
                    _createVNode(_component_el_table, {
                      data: _ctx.tableData,
                      stripe: "",
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_table_column, {
                          prop: "segmentNumber",
                          width: "180",
                          label: _ctx.$t('documentStatistics.segmentNumber')
                        }, null, 8, ["label"]),
                        _createVNode(_component_el_table_column, {
                          prop: "startWith",
                          label: _ctx.$t('documentStatistics.segmentStartsWith')
                        }, null, 8, ["label"])
                      ]),
                      _: 1
                    }, 8, ["data"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onChange"])
    ]),
    _: 1
  }))
}