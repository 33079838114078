import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "labe" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "squeeze",
    onEnter: _ctx.addGlow
  }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_el_divider),
            _createVNode(_component_el_row, { class: "centred" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 3 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('translationConfig.readingDirection')), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 3 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.readingDirestion,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.readingDirestion) = $event)),
                      onChange: _ctx.configChanged,
                      disabled: _ctx.disabled,
                      "default-first-option": ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.directions, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.id,
                            label: item.name,
                            value: item.id
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "onChange", "disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 5 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_text, { type: "info" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" e.g. " + _toDisplayString(_ctx.sample), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onEnter"]))
}