import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialod-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_tour_step = _resolveComponent("el-tour-step")!
  const _component_el_tour = _resolveComponent("el-tour")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_button, {
      type: "primary",
      onClick: _ctx.startTour,
      disabled: !_ctx.tourAvailable
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.tourName), 1)
      ]),
      _: 1
    }, 8, ["onClick", "disabled"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.mergeTourDialog,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mergeTourDialog) = $event)),
      title: _ctx.title,
      width: "400"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.description) + " ", 1),
          _createElementVNode("div", {
            class: "img-button-container merge-button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.continueMergeTour && _ctx.continueMergeTour(...args)))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("span", { class: "material-symbols-outlined" }, " expand ", -1)
          ])),
          _createTextVNode(" " + _toDisplayString(_ctx.hotkey) + " ", 1),
          _createVNode(_component_el_button, {
            type: "primary",
            class: "merge-button",
            onClick: _ctx.continueMergeTour
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Next ")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_el_tour, {
      modelValue: _ctx.tourStart,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tourStart) = $event)),
      "target-area-clickable": false,
      "scroll-into-view-options": { block: 'center' }
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tourSteps, (step) => {
          return (_openBlock(), _createBlock(_component_el_tour_step, {
            key: step.id,
            target: step.target,
            title: step.title,
            description: step.description
          }, null, 8, ["target", "title", "description"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}