<template>
    <el-card>
        <el-collapse v-model="collapseModel"
                     @change="handleChange">

            <el-collapse-item name="1">
                <template #title>
                    <div class="img-container">
                        <span class="material-symbols-outlined">
                            equalizer
                        </span>
                    </div>
                    <span class="title-value">{{tittle}}</span>

                    <div v-if="limitReached"
                         class="limit-container">

                        <el-tooltip placement="top"
                                    :content="tooltipMessage">
                            <div class="img-container">
                                <i class="limit-reached"></i>
                            </div>
                        </el-tooltip>

                    </div>
                </template>

                <el-descriptions>
                    <el-descriptions-item :label="$t('documentStatistics.documentsName')">
                        {{documentName}}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="documentInformation.pageCount"
                                          :label="pageCountLabel">
                        {{documentInformation.pageCount}}
                    </el-descriptions-item>
                    <el-descriptions-item v-if="documentInformation.wordCount"
                                          :label="$t('documentStatistics.wordCount')">
                        {{documentInformation.wordCount}}
                    </el-descriptions-item>
                </el-descriptions>

                <div v-if="limitReached">
                    <div class="title">
                        {{$t('documentStatistics.segmentsThatReachedLimit')}}
                    </div>
                    <el-table :data="tableData" stripe style="width:100%">
                        <el-table-column prop="segmentNumber" 
                                         width="180"
                                         :label="$t('documentStatistics.segmentNumber')" />
                        <el-table-column prop="startWith" 
                                         :label="$t('documentStatistics.segmentStartsWith')" />
                    </el-table>
                </div>

            </el-collapse-item>

        </el-collapse>
    </el-card>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';

    import { DocumentType } from '@/models/DocumentType'

    export default defineComponent({
        name: 'DocumentStatistics',
        props: {
            documentInformation: {
                type: Object,
                required: true,
            },
            documentName: {
                type: String,
                required: true,
            },
            documentType: {
                type: Number as PropType<DocumentType>,
                defaultValue: DocumentType.Docx
            },
        },
        data() {
            return {
                collapseModel: [] as string[],
                collapsedItems: [] as string[],

                tooltipMessage: this.$t('documentStatistics.fileContainSegmentsWithLimit'),
                tableData: [] as any,
                limitReached: false,

                documentStatistics: this.$t('documentStatistics.documentStatistics'),
                pageCount: this.$t('documentStatistics.pageCount'),
                sheetCount: this.$t('documentStatistics.sheetCount'),
            };
        },
        mounted() {
            this.init();
        },
        computed: {
            tittle(): string {
                if (this.collapseModel.includes('1')) {
                    return this.documentStatistics;
                }

                return `${this.documentStatistics} '${this.documentName}'`;
            },
            pageCountLabel(): string {
                let label = ""

                switch (this.documentType) {
                    case DocumentType.Docx:
                        label = this.pageCount;
                        break;

                    case DocumentType.Xlsx:
                        label = this.sheetCount;
                        break;
                }
                return label;
            },
        },
        watch: {
            tableData() {
                this.limitReached = this.tableData.length > 0;
            }
        },
        methods: {
            init() {
                this.tableData = this.documentInformation.invalidSegments
            },
            handleChange(val: string[]) {
                this.collapseModel = val;
            },
        },
    });

</script>

<style  lang="scss" scoped>
    .title-value {
        font-size: 19px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .limit-container {
        margin-left: 10px;
    }

    .limit-reached {
        background-image: url('@/images/alert.svg');
        width: 24px;
        height: 22px;
        background-repeat: no-repeat;
    }

    .title {
        display: flex;
        font-size: 16px;
        margin-top: 15px;
    }
</style>