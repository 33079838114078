import { I18n } from 'vue-i18n';
export class shortcuts {

    static moveNext: ShortcutValue = {
        win: 'Tab',
        mac: 'Tab',
        actionKey: 'shotrcuts.moveNext',
        shortNameKey: ''
    }

    static moveUp: ShortcutValue = {
        win: 'Shift + Tab',
        mac: 'Shift + Tab',
        actionKey: 'shotrcuts.moveUp',
        shortNameKey: ''
    }

    static saveMoveNext: ShortcutValue = {
        win: 'Alt + Right',
        mac: 'Cmd + Right',
        actionKey: 'shotrcuts.moveUp',
        shortNameKey: ''
    }

    static saveMoveUp: ShortcutValue = {
        win: 'Alt + Left',
        mac: 'Cmd + Left',
        actionKey: 'shotrcuts.saveMoveUp',
        shortNameKey: ''
    }

    static goToNextPage: ShortcutValue = {
        win: 'Alt + PageUp',
        mac: 'Cmd + PageUp',
        actionKey: 'shotrcuts.goToNextPage',
        shortNameKey: ''
    }

    static goToPreviousPage: ShortcutValue = {
        win: 'Alt + PageDown',
        mac: 'Cmd + PageDown',
        actionKey: 'shotrcuts.goToNextPage',
        shortNameKey: ''
    }

    static copy: ShortcutValue = {
        win: 'Alt + C',
        mac: 'Cmd + C',
        actionKey: 'shotrcuts.copy',
        shortNameKey: 'shotrcuts.copyShort'
    }

    static translate: ShortcutValue = {
        win: 'Alt + T',
        mac: 'Cmd + T',
        actionKey: 'shotrcuts.translate',
        shortNameKey: 'shotrcuts.translateShort'
    }

    static addTranslation: ShortcutValue = {
        win: 'Alt + S',
        mac: 'Cmd + S',
        actionKey: 'shotrcuts.addTranslation',
        shortNameKey: 'shotrcuts.addTranslationShort'
    }

    static updateTranslation: ShortcutValue = {
        win: 'Alt + U',
        mac: 'Cmd + U',
        actionKey: 'shotrcuts.updateTranslation',
        shortNameKey: 'shotrcuts.updateTranslationShort'
    }

    static discardTranslation: ShortcutValue = {
        win: 'Alt + Backspace',
        mac: 'Cmd + Backspace',
        actionKey: 'shotrcuts.discardTranslation',
        shortNameKey: 'shotrcuts.discardTranslationShort'
    }

    static clearStyles: ShortcutValue = {
        win: 'Alt + Q',
        mac: 'Cmd + Q',
        actionKey: 'shotrcuts.clearStyles',
        shortNameKey: 'shotrcuts.clearStylesShort'
    }

    static variantsPopover: ShortcutValue = {
        win: 'Alt + V',
        mac: 'Cmd + V',
        actionKey: 'shotrcuts.variantsPopover',
        shortNameKey: 'shotrcuts.variantsPopoverShort'
    }

    static nextTranslateSegment: ShortcutValue = {
        win: 'Alt + N',
        mac: 'Cmd + N',
        actionKey: 'shotrcuts.nextTranslateSegment',
        shortNameKey: 'shotrcuts.nextTranslateSegmentShort'
    }

    static merge: ShortcutValue = {
        win: 'Ctrl + M',
        mac: 'Ctrl + M',
        actionKey: 'shotrcuts.merge',
        shortNameKey: 'shotrcuts.mergeShort'
    }

    static split: ShortcutValue = {
        win: 'Ctrl + Shift + S',
        mac: 'Ctrl + Shift + S',
        actionKey: 'shotrcuts.split',
        shortNameKey: 'shotrcuts.splitShort'
    }

    static addSegmentToMerge: ShortcutValue = {
        win: 'Ctrl + ArrowDown',
        mac: 'Ctrl + ArrowDown',
        actionKey: 'shotrcuts.addSegmentToMerge',
        shortNameKey: 'shotrcuts.addSegmentToMergeShort'
    }
    static removeSegmentToMerge: ShortcutValue = {
        win: 'Ctrl + ArrowUp',
        mac: 'Ctrl + ArrowUp',
        actionKey: 'shotrcuts.removeSegmentToMerge',
        shortNameKey: 'shotrcuts.removeSegmentToMergeShort'
    }

    static saveMerge: ShortcutValue = {
        win: 'Ctrl + S',
        mac: 'Ctrl + S',
        actionKey: 'shotrcuts.saveMerge',
        shortNameKey: 'shotrcuts.saveMerge'
    }

    static discardMerge: ShortcutValue = {
        win: 'Ctrl + X',
        mac: 'Ctrl + X',
        actionKey: 'shotrcuts.discardMerge',
        shortNameKey: 'shotrcuts.discardMerge'
    }

    static getTooltipTranslated(t: any, shortCut: ShortcutValue) {
        return `${t(shortCut.shortNameKey)} (${shortCut.win})`
    }
}

export interface ShortcutValue {
    win: string
    mac: string
    actionKey: string
    shortNameKey: string
}
