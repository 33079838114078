<template>
    <div id="home-page-tabs"
         class="home-page">

        <el-tabs v-model="activeTabName"
                 type="border-card">

            <el-tab-pane name="userDocuments"
                         lazy
                         :label="$t('home.continueTranslation')">
                <UserDocuments :key="updateUpload"
                               @go-file-upload="goFileUpload"
                               @document-achived="documentAchived"
                               @document-deleted="documentDeleted" />
            </el-tab-pane>

            <el-tab-pane name="fileUpload"
                         lazy
                         :label="$t('home.newFile')">
                <FileUpload :key="updateUpload"
                            @upload-error="uploadError" />
            </el-tab-pane>

            <el-tab-pane name="archiveDocuments"
                         lazy
                         :label="$t('home.archive')">
                <ArchiveDocuments :key="updateArchive" />
            </el-tab-pane>

        </el-tabs>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    import UserDocuments from './Tabs/UserDocuments/UserDocuments.vue'
    import FileUpload from './Tabs/FileUpload/FileUpload.vue'
    import ArchiveDocuments from './Tabs/ArchivedDocuments/ArchivedDocuments.vue'

    import { notificationService } from '@/services/notificationService'

    export default defineComponent({
        components: {
            UserDocuments,
            FileUpload,
            ArchiveDocuments,
        },
        data() {
            return {
                notificationService: new notificationService(),
                activeTabName: 'userDocuments',
                updateUpload: 0,
                updateArchive: 0,
                tittle: this.$t("home.readingIssueTitle"),
                text: this.$t("home.readingIssueText"),
            };
        },
        methods: {
            goFileUpload(){
                this.activeTabName = 'fileUpload';
            },
            goUserDocuments() {
                this.activeTabName = 'userDocuments';
            },
            documentAchived(){
                this.updateArchive ++;
                this.updateUpload ++;
            },
            documentDeleted(){
                this.updateUpload ++;
            },
            uploadError() {
                this.notificationService.warning(this.tittle, this.text);

                this.goUserDocuments();
                this.updateUpload ++;
            }
        },
    });
</script>
